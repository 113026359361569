import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    pageRoot: {
        padding: 18,
    },

    copyRightAlert: {
        zIndex: 1,
    },

    gallery: {
        padding: 8,
        margin: "0 auto",
    },

    collapseButton: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        maxWidth: "1280px",
        margin: "0 auto",

        "& > .MuiButton-label": {
            flex: 0,
            flexBasis: "50px",
            textTransform: "capitalize",
            color: COLORS.PUMPKIN_ORANGE,
            fontWeight: "bold",
            whiteSpace: "nowrap",
        },
        "&::after": {
            content: "''",
            flex: "1 1 auto",
            width: "100%",
            height: "1px",
            marginLeft: "7px",
            backgroundColor: COLORS.PUMPKIN_ORANGE,
        },
    },

    galleryHeader: {
        marginBottom: 8,
    },

    videosContainer: {
        maxWidth: "1280px",
        margin: "0 auto",
    },

    contentCard: {
        padding: 8,
        margin: "0 auto",
        maxWidth: "100%",
    },

    galleryContent: {
        marginTop: 20,
    },

    checkboxGrid: {
        flexBasis: 0,
        whiteSpace: "nowrap",
    },
});

export default styles;
