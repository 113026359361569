import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const borderStyle = `1px ${COLORS.NAV_COLOR_2} solid`;

export default makeStyles({
    // "@media screen and (min-width: 530px)": {
    accountContent: {
        position: "absolute",
        top: "75px",
        left: "0",
        width: "100%",
        backgroundColor: COLORS.WHITE,
        border: borderStyle,
        zIndex: 20,

        "& > ul": {
            margin: 0,
            padding: "20px 0",
            borderBottom: borderStyle,
            "& > li": {
                listStyle: "none",
                marginLeft: 15,
                color: COLORS.NAV_COLOR_4,
                fontSize: "0.8rem",
            },
            "& a": {
                color: COLORS.NAV_COLOR_4,
            },

            "& button": {
                padding: 0,
                margin: 0,
            },
        },

        "& > ul:last-child": {
            border: "none",
        },

        "& $userInfo": {
            fontSize: "0.8rem",
            fontStyle: "italic",
            color: COLORS.BLACK,
        },
    },

    userInfo: {},
    signOutButton: {
        fontSize: "0.8rem",
    },
    // },

    "@media screen and (min-width: 1000px)": {
        accountContent: {
            top: "35px",
        },
    },

    "@media screen and (min-width: 1400px)": {},
});
