import { makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";

export default makeStyles({
    button: {
        "&$disabled": {
            backgroundColor: "gray",
        },
    },
    circularProgress: {
        color: COLORS.WHITE,
        padding: 7,
    },

    disabled: {},
});
