import React from "react";
import PropTypes from "prop-types";

import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

function EnhancedTableHead(props) {
    const {
        selectionDisabled,
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        columns,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: COLORS.background, padding: "0px", margin: "0px" }}>
                {selectionDisabled ? null : (
                    <TableCell padding="checkbox">
                        <Checkbox
                            style={{
                                // color: "#d50000",
                                color: COLORS.primary,
                            }}
                            // disabled={true}
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "select all desserts" }}
                        />
                    </TableCell>
                )}
                {columns.map((row) =>
                    row.hidden ? null : row.id === "time" ? (
                        <TableCell
                            key={row.id}
                            align={row.numeric ? "right" : "left"}
                            padding={row.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === row.id ? order : false}
                            style={{ color: COLORS.primary, fontWeight: "500", fontSize: "14px" }}
                        >
                            {row.label}
                        </TableCell>
                    ) : (
                        <TableCell
                            key={row.id}
                            align={row.numeric ? "right" : "left"}
                            padding={row.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <TableSortLabel
                                style={{
                                    color: COLORS.primary,
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                }}
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
