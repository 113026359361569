import React from "react";
import { Redirect, Route, useLocation } from "react-router";
import { EmailVerificationBar } from "..";
import PATHS from "../../constants/paths";
import { useAuth } from "../../contexts/auth-context";
import { useGeolocation } from "../../contexts/geolocation-context";
import { getDaysSinceSpecificDate } from "../../helpers/date-utils";
import { FullPageError } from "..";

const PublicRoute = (props) => {
    const { currentUser, companyData, userData } = useAuth();
    // const { isSupported, permissionStatus } = useGeolocation();

    const location = useLocation();
    const { children } = props;

    //if the user is verified OR not verified and it's been 10 days or less since registration, let him in
    if (currentUser && companyData && userData) {
        const daysElapsed = getDaysSinceSpecificDate(userData.created_at);
        if (currentUser.emailVerified || (!currentUser.emailVerified && daysElapsed <= 10)) {
            return <Redirect to={{ pathname: location.state?.from ?? PATHS.TUTORIALS }} />;
        }
    }

    // if (!isSupported) {
    //     return (
    //         <Route {...props}>
    //             <FullPageError>
    //                 <div style={{ textAlign: "center" }}>
    //                     <p>Your browser does not support Geolocation Feature.</p>
    //                     <p>Please update your browser or try another one.</p>
    //                 </div>
    //             </FullPageError>
    //         </Route>
    //     );
    // }

    // if (permissionStatus === "denied") {
    //     return (
    //         <Route {...props}>
    //             <FullPageError>
    //                 <div style={{ textAlign: "center" }}>
    //                     <p>You disabled Geolocation feature in your browser.</p>
    //                     <p>For Account's security reasons, please enable it in your browser settings.</p>
    //                 </div>
    //             </FullPageError>
    //         </Route>
    //     );
    // }

    return (
        <Route {...props}>
            {currentUser && companyData && userData && <EmailVerificationBar />}
            {children}
        </Route>
    );
};

export default PublicRoute;
