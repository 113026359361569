import React, { useState } from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import "../../../core-ui/core-styles/pdf/fonts";
import { REPORT_IDS } from "../../../constants/constants-lagacy";
import styles from "./styles";
let productsPerPage = 20;
let counter = 0;

function timestamp(time) {
    let date = time.toDate();
    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
}

let createRow = (row, style) => {
    if (!row)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${row.id}`}>
                <Text style={styles.lastCell}>Error getting the merchandiser's data</Text>
            </View>
        );
        counter++;

    return (
        <View style={style} key={`PDF Row ${Math.random()}`}>
         <View style={styles.indexCell}>
                <Text>{counter}</Text>
            </View>
            <View style={styles.cell}>
                <Text>{row.code}</Text>
            </View>

            <View style={styles.cell}>
                <Text style={styles.cellText}>{row.barcode}</Text>
            </View>
            <View style={styles.cellName}>
                <Text style={styles.marginLeft}>{row.name}</Text>
            </View>
            <View style={styles.cell}>
                <Text style={styles.cellText}>{row.packing}</Text>
            </View>

            <View style={styles.rowDataGroup}>
                <View style={styles.lastDataRowGroup}>
                    <View style={styles.columnGroup}>
                        <View style={styles.cell}>
                            <Text>{row.quantity_on_shelf}</Text>
                        </View>
                        <View style={styles.lastCell}>
                            <Text>{row.quantity_on_extra_display}</Text>
                        </View>
                    </View>
                </View>
            </View>

            <View style={styles.warehouseCell}>
                <Text>{row.warehouse_draw}</Text>
            </View>
            <View style={styles.lastDataRowGroup}>
                <View style={styles.lastDataRowGroup}>
                    <View style={styles.columnGroup}>
                        <View style={styles.cell}>
                            <Text>{row.total_quantity_on_shelf}</Text>
                        </View>
                        <View style={styles.lastCell}>
                            <Text>{row.total_quantity_on_extra_display}</Text>
                        </View>
                    </View>
                </View>
            </View>

        </View>
    );
};

let createPage = (task, pageNum, companyData) => {
    const { pdfRows } = task;
    let rowsToDisplay = pdfRows.slice(pageNum * productsPerPage, pageNum * productsPerPage + productsPerPage);

    const companyLogo = companyData.logo;
    const companyName = companyData.company_name;

    return (
        <Page
            size="A4"
            orientation="portrait"
            style={styles.page}
            key={`Daily Merchandising Report ${Math.random()}-${pageNum}`}
        >
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Daily Merchandising Report</Text>

            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <Text>
                        Visit No.: <Text style={styles.bold}>{task.task_id}</Text> | Time in:
                        <Text style={styles.bold}>
                            {task.started_at === null ? "-" : timestamp(task.started_at)}{" "}
                            <span>&nbsp; &nbsp; &nbsp;</span>
                        </Text>{" "}
                        Time Out:
                        <Text style={styles.bold}>{task.finished_at === null ? "-" : timestamp(task.finished_at)}</Text>
                        <Text>
                            Date: <Text style={styles.bold}>{task.outletBranch.En_short_name}</Text>
                        </Text>
                    </Text>
                    <Text>
                        Outlet Branch: <Text style={styles.bold}>{task.outletBranch.En_short_name}</Text>
                    </Text>
                </View>
            </View>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={styles.headerRow}>
                        <View style={styles.indexCell}>
                                <Text style={styles.headerCellText}></Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Product No</Text>
                            </View>

                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Barcode</Text>
                            </View>
                            <View style={styles.headerNameCell}>
                                <Text style={styles.headerCellText}>Product Name</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Packing</Text>
                            </View>

                            {/* Cell Group spans mulitple rows */}
                            <View style={styles.rowGroup}>
                                <View style={styles.rowGroupRow}>
                                    <View style={styles.lastHeaderCell}>
                                        <Text style={styles.headerCellText}>Qty On</Text>
                                    </View>
                                </View>
                                <View style={styles.lastRowGroupRow}>
                                    {/* Cell Group spans mulitple column */}
                                    <View style={styles.columnGroup}>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Dspl</Text>
                                        </View>
                                        <View style={styles.lastHeaderCell}>
                                            <Text style={styles.headerCellText}>E.D.</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.headerDrawCell}>
                                <Text style={styles.headerCellText}>W/H</Text>
                                <Text style={styles.headerCellText}>Draw</Text>
                            </View>

                            {/* Cell Group spans mulitple rows */}
                            <View style={styles.lastRowGroup}>
                                <View style={styles.rowGroupRow}>
                                    <View style={styles.lastHeaderCell}>
                                        <Text style={styles.headerCellText}>Qty Dspl'ed</Text>
                                    </View>
                                </View>
                                <View style={styles.lastRowGroupRow}>
                                    {/* Cell Group spans mulitple column */}
                                    <View style={styles.columnGroup}>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Dspl</Text>
                                        </View>
                                        <View style={styles.lastHeaderCell}>
                                            <Text style={styles.headerCellText}>E.D.</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View>
                        {rowsToDisplay.map((row, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            return createRow(row, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.idContainer}>
                <Text>{REPORT_IDS.DAILY_MERCHANDISING}</Text>
            </View>
           
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
           
                <View style={styles.logoFooterFlex}>
                    <Text style={styles.logoFooterText}>Powered By:</Text>
                </View>
                <View style={styles.logoFooterFlex}>
                    <Image style={styles.logo} src={logo} />
                </View>
            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { mainTasks } = info;

    mainTasks.forEach((task) => {
        const taskDetails = task.taskDetails;

        const pdfRows = taskDetails.reduce((prev, td) => {
            let { product, extra_display, quantity_on, quantity_added, warehouse_actual_draw } = td;
            quantity_on = quantity_on ?? 0;
            quantity_added = quantity_added ?? 0;
            warehouse_actual_draw = warehouse_actual_draw ?? 0;

            const { product_id, En_name, barcode, product_number } = product;
            const found = prev.find((row) => row.product_id === product_id);

            if (found) {
                if (extra_display) {
                    found.quantity_on_extra_display += quantity_on;
                    found.total_quantity_on_extra_display += quantity_on + quantity_added;
                } else {
                    found.quantity_on_shelf += quantity_on;
                    found.total_quantity_on_shelf += quantity_on + quantity_added;
                }
            } else {
                prev.push({
                    product_id,
                    code: product_number,
                    name: En_name,
                    barcode: barcode,
                    packing:`${product.outer_unit <= 1 ? "" : product.outer_unit + "x"}${product.packing.no_units <= 1 ? "" : product.packing.no_units + "x"}${product.packing.size}${product.packing.size_unit}`,
                    quantity_on_shelf: extra_display ? 0 : quantity_on,
                    quantity_on_extra_display: extra_display ? quantity_on : 0,
                    warehouse_draw: warehouse_actual_draw,
                    total_quantity_on_shelf: extra_display ? 0 : quantity_on + quantity_added,
                    total_quantity_on_extra_display: extra_display ? quantity_on + quantity_added : 0,
                });
            }

            return prev;
        }, []);
        pdfRows.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        });

        task.pdfRows = pdfRows;
    });

    let pages = [];
    mainTasks.forEach((task) => {
        const totalPages = Math.ceil(task.pdfRows.length / productsPerPage);
        const lastPage = pages.length;
        for (let pageNum = lastPage; pageNum < lastPage + totalPages; pageNum++) {
            pages[pageNum] = createPage(task, pageNum - lastPage, info.companyData);
        }
    });
    return pages;
};

// Create Document Component
const DailyMerchandisingReportPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default DailyMerchandisingReportPdf;
