import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    cardContent: {
        height: "calc(100% - 50px)",
        width: "100%",
        padding: "0",
        paddingBottom: "0 !important",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
});

export default styles;
