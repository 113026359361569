const PATHS = {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",

    DASHBOARD: "/dashboard",
    HELP: "/help",
    TUTORIALS: "/Tutorials",

    ACCOUNT_INFO: "/account-info",
    UPDATE_ACCOUNT_INFO: "/update-account-info",

    BRANCHES: "/branches",
    ADD_BRANCH: "/add-branch",
    UPDATE_BRANCH: "/update-branch",

    EMPLOYEES: "/employees",
    ADD_EMPLOYEE: "/add-employee",
    UPDATE_EMPLOYEE: "/update-employee",

    SUPERVISORS: "/supervisors",
    ADD_SUPERVISOR: "/add-supervisor",
    UPDATE_SUPERVISOR: "/update-supervisor",

    PRODUCTS: "/products",
    ADD_PRODUCT: "/add-product",
    UPDATE_PRODUCT: "/update-product",

    EXTRA_DISPLAY_CONTRACTS: "/extra-display-contract",
    ADD_EXTRA_DISPLAY_CONTRACT: "/add-extra-display-contract",
    UPDATE_EXTRA_DISPLAY_CONTRACT: "/update-extra-display-contract",

    LINK_OUTLETS: "/link-outlets",
    LINK_OUTLET_BRANCHES: "/link-outlet-branches",
    LINKED_OUTLETS: "/linked-outlets",
    LINK_OUTLET_WITH_PRODUCTS: "/link-outlet-products",

    //* SERVICES
    INSOURCE_PROJECTS: "/insource-projects",
    ADD_INSOURCE_PROJECT: "/add-insource-project",
    MANAGE_INSOURCE_PROJECT: "/manage-insource-project",

    OUTSOURCE_PROJECTS: "/outsource-projects",
    ADD_OUTSOURCE_PROJECT: "/add-outsource-project",
    MANAGE_OUTSOURCE_PROJECT: "/manage-outsource-project",

    //* OPERATIONS
    INSOURCE_VISITS_MANAGEMENT: "/insource-visits-management",
    INSOURCE_VISIT_DETAILS: "/insource-visit-details",
    INSOURCE_VISIT_REPORT: "/insource-visit-report",

    OUTSOURCE_VISITS_MANAGEMENT: "/outsource-visits-management",
    OUTSOURCE_VISIT_DETAILS: "/outsource-visit-details",
    OUTSOURCE_VISIT_REPORT: "/outsource-visit-report",

    ROUTE_PLAN_MANAGEMENT: "/route-plan-management",
    ROUTE_VISITS_MANAGEMENT: "/route-visits-management",

    EXCLUSIVITY_CONTRACTS: "/exclusivity-contracts",
    ADD_EXCLUSIVITY_CONTRACT: "/add-exclusivity-contract",
    UPDATE_EXCLUSIVITY_CONTRACT: "/update-exclusivity-contract",

    GALLERY: "/gallery",

    VISITS_POOL: "/visits-pool",

    //* REPORTS
    DAILY_MERCHANDISING_REPORT: "/daily-merchandising-report",
    MERCHANDISER_ATTENDANCE_REPORT: "/merchandiser-attendance-report",
    EXPIRY_AND_DAMAGE_REPORT: "/expiry-and-damage-report",

    //* SUBSCRIPTION
    SUBSCRIPTIONS: "/subscriptions",
};

export default PATHS;
