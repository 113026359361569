import { createContext, useCallback, useContext, useEffect, useState } from "react";
import useMap from "../hooks/use-map";

const options = {
    enableHighAccuracy: true,
    timeout: 30000, // integer (milliseconds) - the amount of time before the error callback is invoked if 0 it will never invoke.
    maximumAge: 0, // integer (milliseconds) | infinity - maximum cached position age.
};
const success = async (pos, setPosition) => {
    const lat = pos.coords.latitude;
    const lng = pos.coords.longitude;

    const res = await new window.google.maps.Geocoder().geocode({
        location: { lat, lng },
    });

    if (res.results.length <= 0) throw new Error(`Could not find address with these coordinates ${lat}, ${lng}`);

    const { formatted_address, address_components } = res.results[0];
    setPosition({ lat, lng, address: formatted_address, address_components });
};

const errors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
};

const GeolocationContext = createContext({
    isSupported: true,
    permissionStatus: "",
    position: null,
});

export const GeolocationContextProvider = (props) => {
    const { isLoaded } = useMap();
    const [isSupported, setIsSupported] = useState(true);
    const [permissionStatus, setPermissionStatus] = useState(null);
    const [position, setPosition] = useState(null);

    const getPosition = () => {
        navigator.geolocation.getCurrentPosition((pos) => success(pos, setPosition), errors, options);
    };

    const getGeoIpPosition = async () => {
        try {
            const res = await fetch(
                `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                { method: "POST" }
            );

            const { location } = await res.json();
            await success({ coords: { latitude: location.lat, longitude: location.lng } }, setPosition);
        } catch (error) {
            errors(error);
        }
    };

    const init = useCallback(async () => {
        if (!isLoaded) return;

        try {
            if (!"geolocation" in navigator) {
                setIsSupported(false);
                return;
            }

            const geolocation = await navigator.permissions.query({ name: "geolocation" });
            switch (geolocation.state) {
                case "granted":
                case "prompt":
                    getPosition();
                    setPermissionStatus(geolocation.state);
                    break;
                case "denied":
                    getGeoIpPosition();
                    setPermissionStatus(geolocation.state);
                    break;

                default:
                    setPermissionStatus(null);
                    break;
            }
            setIsSupported(true);
        } catch (error) {
            console.log(error);
            setPermissionStatus(null);
            setIsSupported(false);
        }
    }, [isLoaded]);

    useEffect(() => {
        init();
    }, [isLoaded]);

    const contextValue = {
        isLoaded,
        isSupported,
        permissionStatus,
        position,
    };

    return <GeolocationContext.Provider value={contextValue}>{props.children}</GeolocationContext.Provider>;
};

export const useGeolocation = () => {
    return useContext(GeolocationContext);
};

export default GeolocationContext;
