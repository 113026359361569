import { alpha, darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import headerBackground from "../../assets/images/background1.jpg";
import COLORS from "../../constants/colors";

const borderStyle = `1px ${COLORS.NAV_COLOR_2} solid`;

export default makeStyles({
    headerWrap: {
        width: "100%",
        height: "auto",
        borderBottom: borderStyle,
        backgroundImage: `linear-gradient(${alpha(COLORS.NAV_COLOR_1, 0.8)}, ${alpha(
            COLORS.NAV_COLOR_1,
            0.8
        )}), url(${headerBackground})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        backgroundPosition: "50% 2100px",
    },

    header: {
        position: "relative",
        width: "100%",
        maxWidth: "1280px",
        margin: "0 auto",
    },

    logoWrap: {
        display: "flex",
        justifyContent: "flex-start",
        // justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderBottom: "1px solid gray",
    },

    emahamLogo: {
        width: 40,
        height: 53,
        // margin: "10px auto",
        margin: "10px 40px",
        "& img": {
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "contain",
        },
    },

    navbarToggle: {
        position: "absolute",
        top: "0",
        left: "0",
        padding: "27px 44px",
        margin: "2px",
        "& div": {
            cursor: "pointer",
        },
    },

    // account: {
    //     display: "none",
    // },

    menuWrap: {
        paddingLeft: "38px",
    },

    collapse: {
        transition: "height 500ms",
        "& h1": {
            margin: "0",
        },
    },

    noTransition: {
        transition: "none",
    },
    collapseContent: {},

    // "@media screen and (min-width: 530px)": {
    account: {
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        // marginTop: "40px",
        marginRight: "40px",
    },

    accountBtn: {
        textTransform: "none !important",
    },

    accountDetails: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        marginRight: "5px",
    },

    username: {
        color: darken(COLORS.WHITE, 0.5),
        fontSize: "0.6rem",
        fontStyle: "italic",
    },

    company: {
        lineHeight: 1.1,
        color: COLORS.WHITE,
        fontSize: "0.8rem",
        fontWeight: 600,
    },

    personIcon: {
        fontSize: "1.4rem",
        color: COLORS.NAV_COLOR_3,
    },
    // },

    "@media screen and (min-width: 1000px)": {
        headerWrap: {
            padding: "0 70px",
        },

        active: {
            paddingTop: 74,
        },

        expanded: {
            position: "fixed",
            top: 0,
            zIndex: 200,
        },

        header: {
            display: "flex",
            justifyContent: "space-between",
        },
        logoWrap: {
            borderBottom: "none",
            position: "static",
        },
        navbarToggle: {
            display: "none",
        },
        emahamLogo: {
            margin: "10px",
        },

        account: {
            bottom: "auto",
            marginTop: "5px",
            marginRight: "9px",
        },

        accountDetails: {
            alignItems: "center",
            flexDirection: "row-reverse",
            justifyContent: "center",
        },

        company: {
            paddingRight: "10px",
            marginRight: "10px",
            borderRight: `1px solid ${COLORS.NAV_COLOR_3}`,
        },

        menuWrap: {
            display: "flex",
            alignItems: "flex-end",
        },

        collapse: {
            transition: "none",
        },
    },
});
