import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    DateRangePickerWrap: {
        position: "relative",
        margin: "20px 0",
        display: "inline-block",
    },

    dateRangeBtn: {
        backgroundColor: "transparent",
        border: "none",
        color: COLORS.BLACK,
        padding: "0",
        fontSize: "0.7rem",
        fontWeight: "bold",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: lighten(COLORS.BLACK, 0.95),
        },
    },

    datePicker: {
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: 999,
    },
    dateRangeFooter: {
        backgroundColor: "#fff",
        borderTop: "1px solid #e0e0e0",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px",
    },

    applyBtn: {
        backgroundColor: "#fff",
        color: COLORS.PRIMARY,
        "&:hover": {
            backgroundColor: lighten(COLORS.PRIMARY, 0.95),
        },
    },

    cancelBtn: {
        backgroundColor: "#fff",
        color: COLORS.BLACK,
        "&:hover": {
            backgroundColor: lighten(COLORS.BLACK, 0.95),
        },
    },

    "@media (min-width: 900px)": {
        dateRangeBtn: {
            fontSize: "0.8rem",
        },
    },
});

export default styles;
