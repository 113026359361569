const COLORS = {
    WHITE: "#fff",
    BLACK: "#000",
    FRENSH_BLUE: "#0070c0",
    LIGHT_EMINENCE: "#e6e3f4",
    MALIBU: "#86C4FF",
    PUMPKIN_ORANGE: "#F27121",
    VALENTINE_RED: "#E94057",

    PRIMARY: "#91041C",

    GRADIANT_1: "linear-gradient( 136deg, #f27121 0%, #e94057 50%, #602f92 100%)",

    NAV_COLOR_1: "#002263",
    NAV_COLOR_2: "#7b90cb",
    NAV_COLOR_3: "#DB715A",
    NAV_COLOR_4: "#7a39dc",

    SUBMIT: "#4BB543",
};

export default COLORS;
