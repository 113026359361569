import { WEEKDAYS } from "../constants/constants-lagacy";
import { getSeasons } from "./firestore-utils";

import { firestore as db } from "../services/firebase";

export let getRegularPrices = async (projectType, outlet_class_id = null) => {
    let prices = [];
    try {
        let ref = db.collection("Price").where("project_type", "==", projectType);

        if (outlet_class_id) {
            ref = ref.where("class_id", "==", outlet_class_id);
        }

        prices = (await ref.get()).docs;
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let getSeasonalPrices = async (projectType, outlet_class_id = null, startDate = null, endDate = null) => {
    let prices = [];
    try {
        let seasonIDs = [];
        let ref = db.collection("Seasonal_Price").where("project_type", "==", projectType);

        if (outlet_class_id) {
            ref = ref.where("class_id", "==", outlet_class_id);
        }
        if (startDate && endDate) {
            seasonIDs = (await getSeasons(startDate, endDate)).map((season) => season.id);
            prices = await loadSeasonalPrices(outlet_class_id, projectType, seasonIDs);
        } else {
            prices = (await ref.get()).docs;
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let loadSeasonalPrices = async (classID = null, projectType, seasonIDs) => {
    let seasonalPrices = [];
    try {
        let seasonalPricesRef = db.collection("Seasonal_Price");

        if (classID) {
            seasonalPrices = await Promise.all(
                seasonIDs.map(async (seasonID) => {
                    const priceID = `${projectType}-${classID}-${seasonID}`;
                    const seasonalPrice = await seasonalPricesRef.doc(priceID).get();
                    return seasonalPrice;
                })
            );
            seasonalPrices = seasonalPrices.filter((sp) => (sp.exists ? true : false));
            // for (const seasonID of seasonIDs) {
            //     const priceID = `${projectType}-${classID}-${seasonID}`;
            //     const seasonalPrice = await seasonalPricesRef.doc(priceID).get();
            //     if (seasonalPrice.exists) seasonalPrices.push(seasonalPrice);
            // }
        } else {
            seasonalPrices = await Promise.all(
                seasonIDs.map(async (seasonID) => {
                    let docs = (
                        await seasonalPricesRef
                            .where("project_type", "==", projectType)
                            .where("season_id", "==", seasonID)
                            .get()
                    ).docs;

                    return docs;
                })
            );

            seasonalPrices = seasonalPrices.reduce((arr, prices) => {
                return [...arr, ...prices];
            }, []);
            // for (const seasonID of seasonIDs) {
            //     let docs = (await seasonalPricesRef
            //         .where("project_type", "==", projectType)
            //         .where("season_id", "==", seasonID)
            //         .get()).docs;

            //     seasonalPrices = [...seasonalPrices, ...docs];
            // }
        }
    } catch (err) {
        console.error(err);
    }
    return seasonalPrices;
};

export let loadWeekendPrices = async (classID = null, projectType, workingDaysIDs) => {
    let weekendPrices = [];
    try {
        let weekendPriceRef = db.collection("Weekend_Price");

        if (classID) {
            weekendPrices = await Promise.all(
                workingDaysIDs.map(async (dayID) => {
                    const priceID = `${projectType}-${classID}-${dayID}`;
                    const weekendPrice = await weekendPriceRef.doc(priceID).get();
                    return weekendPrice;
                })
            );
            weekendPrices = weekendPrices.filter((wp) => (wp.exists ? true : false));
            // for (const dayID of workingDaysIDs) {
            //     const priceID = `${projectType}-${classID}-${dayID}`;
            //     const weekendPrice = await weekendPriceRef.doc(priceID).get();
            //     if (weekendPrice.exists) weekendPrices.push(weekendPrice);
            // }
        } else {
            weekendPrices = await Promise.all(
                workingDaysIDs.map(async (dayID) => {
                    let docs = (
                        await weekendPriceRef
                            .where("project_type", "==", projectType)
                            .where("day_id", "==", dayID)
                            .get()
                    ).docs;

                    return docs;
                })
            );

            weekendPrices = weekendPrices.reduce((arr, prices) => {
                return [...arr, ...prices];
            }, []);
            // for (const dayID of workingDaysIDs) {
            //     let docs = (await weekendPriceRef
            //         .where("project_type", "==", projectType)
            //         .where("day_id", "==", dayID)
            //         .get()).docs;

            //     weekendPrices = [...weekendPrices, ...docs];
            // }
        }
    } catch (err) {
        console.log(err);
    }

    return weekendPrices;
};

export let getEDRegularPrices = async (extraDisplayIDs = []) => {
    let prices = [];
    try {
        let ref = db.collection("Extra_Display_Price");

        if (extraDisplayIDs.length > 0) {
            prices = await Promise.all(
                extraDisplayIDs.map(async (id) => {
                    return await ref.doc(`${id}`).get();
                })
            );

            prices = prices.filter((p) => (p.exists ? true : false));
            // for (const id of extraDisplayIDs) {
            //     let price = await ref.doc(id).get();
            //     prices.push(price);
            // }
        } else {
            prices = (await ref.get()).docs;
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let getSeasonalEDPrices = async (extraDisplayIDs = [], startDate = null, endDate = null) => {
    let prices = [];
    try {
        let seasonIDs = [];
        let ref = db.collection("Seasonal_Extra_Display_Price");

        if (startDate && endDate) {
            seasonIDs = (await getSeasons(startDate, endDate)).map((season) => season.id);
            prices = await loadSeasonalExtraDisplayPrices(extraDisplayIDs, seasonIDs);
        } else {
            prices = await Promise.all(
                extraDisplayIDs.map(async (id) => {
                    return (await ref.where("extra_display_id", "==", id).get()).docs;
                })
            );
            prices = prices.reduce((arr, prices) => [...arr, ...prices], []);

            // for (const extraDisplayID of extraDisplayIDs) {
            //     let seasonalPrices = (await ref.where("extra_display_id", "==", extraDisplayID).get()).docs;
            //     prices = [...prices, ...seasonalPrices];
            // }
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let loadSeasonalExtraDisplayPrices = async (extraDisplayIDs, seasonIDs) => {
    let seasonalPrices = [];
    try {
        let seasonalExtraDisplayPricesRef = db.collection("Seasonal_Extra_Display_Price");

        seasonalPrices = await Promise.all(
            extraDisplayIDs.map(async (exID) => {
                let prices = await Promise.all(
                    seasonIDs.map(async (seasonID) => {
                        const priceID = `${exID}-${seasonID}`;
                        return await seasonalExtraDisplayPricesRef.doc(priceID).get();
                    })
                );
                prices = prices.filter((p) => (p.exists ? true : false));
                return prices;
            })
        );
        seasonalPrices = seasonalPrices.reduce((arr, prices) => [...arr, ...prices], []);

        // for (const extraDisplayID of extraDisplayIDs) {
        //     for (const seasonID of seasonIDs) {
        //         const priceID = `${extraDisplayID}-${seasonID}`;
        //         let seasonalPrice = await seasonalExtraDisplayPricesRef.doc(priceID).get();
        //         if (seasonalPrice.exists) seasonalPrices.push(seasonalPrice);
        //     }
        // }
    } catch (err) {
        console.error(err);
    }
    return seasonalPrices;
};

export let getWeekendEDPrices = async (extraDisplayIDs = [], dayIDs = []) => {
    let prices = [];
    try {
        let ref = db.collection("Weekend_Extra_Display_Price");

        if (dayIDs.length > 0) {
            prices = await loadWeekendExtraDisplayPrices(extraDisplayIDs, dayIDs);
        } else {
            prices = await Promise.all(
                extraDisplayIDs.map(async (id) => {
                    return (await ref.where("extra_display_id", "==", id).get()).docs;
                })
            );
            prices = prices.reduce((arr, prices) => [...arr, ...prices], []);

            // for (const extraDisplayID of extraDisplayIDs) {
            //     let weekendPrices = (await ref.where("extra_display_id", "==", extraDisplayID).get()).docs;
            //     prices = [...prices, ...weekendPrices];
            // }
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let loadWeekendExtraDisplayPrices = async (extraDisplayIDs, workingDaysIDs) => {
    let weekendPrices = [];
    try {
        let weekendExtraDisplayPricesRef = db.collection("Weekend_Extra_Display_Price");

        weekendPrices = await Promise.all(
            extraDisplayIDs.map(async (exID) => {
                let prices = await Promise.all(
                    workingDaysIDs.map(async (dayID) => {
                        const priceID = `${exID}-${dayID}`;
                        return await weekendExtraDisplayPricesRef.doc(priceID).get();
                    })
                );
                prices = prices.filter((p) => (p.exists ? true : false));
                return prices;
            })
        );
        weekendPrices = weekendPrices.reduce((arr, prices) => [...arr, ...prices], []);

        // for (const extraDisplayID of extraDisplayIDs) {
        //     for (const dayID of workingDaysIDs) {
        //         const priceID = `${extraDisplayID}-${dayID}`;
        //         let weekendPrice = await weekendExtraDisplayPricesRef.doc(priceID).get();
        //         if (weekendPrice.exists) weekendPrices.push(weekendPrice);
        //     }
        // }
    } catch (err) {
        console.error(err);
    }
    return weekendPrices;
};

let calculateNormalPrice = (date, dayID, outletBranch) => {
    let price = null;
    let sum = {
        cost: { general_merchandising: 0, task_per_hour: 0, task_per_minute: 0, warehouse: 0 },
        selling: { general_merchandising: 0, task_per_hour: 0, task_per_minute: 0, warehouse: 0 },
        coefficient: 1,
    };
    const numberOfProducts = outletBranch.number_of_products;
    // seasonal
    let season = outletBranch.seasons.find((season) => {
        return season.start_date.toDate() <= date && season.end_date.toDate() >= date;
    });

    if (season) {
        const seasonalPriceID = `101-${outletBranch.class_id}-${season.id}`;
        price = outletBranch.normal_prices.seasonal.find((p) => p.price_id === seasonalPriceID);
        if (price) {
            let coefficient = price.coefficient ? price.coefficient : 1;
            if (numberOfProducts > 0 && numberOfProducts < 10) coefficient *= 1;
            else if (numberOfProducts < 20) coefficient *= 2;
            else if (numberOfProducts < 30) coefficient *= 3;
            else coefficient = numberOfProducts;

            for (const key in price.cost) {
                if (Object.hasOwnProperty.call(price.cost, key) && Object.hasOwnProperty.call(price.selling, key)) {
                    sum.cost[key] += Math.max(price.cost[key] * coefficient, price.cost[key] * numberOfProducts);
                    sum.cost.pricePerProduct += price.cost[key];

                    sum.selling[key] += Math.max(
                        price.selling[key] * coefficient,
                        price.selling[key] * numberOfProducts
                    );
                    sum.selling.pricePerProduct += price.selling[key];
                }
            }

            sum.coefficient = coefficient;
            return sum;
        }
    }

    //weekend
    price = outletBranch.normal_prices.weekend.find((p) => p.id === `101-${outletBranch.class_id}-${dayID}`);
    if (price) {
        let coefficient = price.coefficient ? price.coefficient : 1;
        if (numberOfProducts > 0 && numberOfProducts < 10) coefficient *= 1;
        else if (numberOfProducts < 20) coefficient *= 2;
        else if (numberOfProducts < 30) coefficient *= 3;
        else coefficient = numberOfProducts;

        for (const key in price.cost) {
            if (Object.hasOwnProperty.call(price.cost, key) && Object.hasOwnProperty.call(price.selling, key)) {
                sum.cost[key] += Math.max(price.cost[key] * coefficient, price.cost[key] * numberOfProducts);
                sum.cost.pricePerProduct += price.cost[key];

                sum.selling[key] += Math.max(price.selling[key] * coefficient, price.selling[key] * numberOfProducts);
                sum.selling.pricePerProduct += price.selling[key];
            }
        }
        sum.coefficient = coefficient;
        return sum;
    }

    //regular
    price = outletBranch.normal_prices.regular;
    if (!price)
        return {
            cost: { general_merchandising: 0, task_per_hour: 0, task_per_minute: 0, warehouse: 0 },
            selling: { general_merchandising: 0, task_per_hour: 0, task_per_minute: 0, warehouse: 0 },
            coefficient: 1,
        };

    let coefficient = price.coefficient ? price.coefficient : 1;
    if (numberOfProducts > 0 && numberOfProducts < 10) coefficient *= 1;
    else if (numberOfProducts < 20) coefficient *= 2;
    else if (numberOfProducts < 30) coefficient *= 3;
    else coefficient = numberOfProducts;
    return {
        cost: {
            general_merchandising: Math.max(
                price.general_merchandising_cost * coefficient,
                price.general_merchandising_cost * numberOfProducts
            ),
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: Math.max(price.wherhouse_v_cost * coefficient, price.wherhouse_v_cost * numberOfProducts),
            pricePerProduct: price.general_merchandising_cost + price.wherhouse_v_cost,
        },
        selling: {
            general_merchandising: Math.max(
                price.general_merchandising_selling * coefficient,
                price.general_merchandising_selling * numberOfProducts
            ),
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: Math.max(price.wherhouse_v_selling * coefficient, price.wherhouse_v_selling * numberOfProducts),
            pricePerProduct: price.general_merchandising_selling + price.wherhouse_v_selling,
        },
        coefficient: coefficient,
    };
};

let calculateEDPrice = (contractPrice, date, dayID, seasons, price) => {
    const types = contractPrice.types;
    if (types.length <= 0) return { cost: 0, selling: 0 };

    let cost = 0;
    let selling = 0;
    let season = seasons.find((season) => {
        return season.data().start_date.toDate() <= date && season.data().end_date.toDate() >= date;
    });

    for (const type of types) {
        let edPrice = null;
        // seasonal
        if (season) {
            const seasonalPriceID = `${type.id}-${season.id}`;
            edPrice = type.seasonal.find((p) => p.id === seasonalPriceID);
        }
        if (edPrice) {
            // cost += edPrice.cost * type.num_of_products;
            // selling += edPrice.selling * type.num_of_products;
            cost += edPrice.cost + type.num_of_products * price.cost.pricePerProduct;
            selling += edPrice.selling + type.num_of_products * price.selling.pricePerProduct;
            continue;
        }

        //weekend
        edPrice = type.weekend.find((p) => p.id === `${type.id}-${dayID}`);
        if (edPrice) {
            // cost += edPrice.cost * type.num_of_products;
            // selling += edPrice.selling * type.num_of_products;
            cost += edPrice.cost + type.num_of_products * price.cost.pricePerProduct;
            selling += edPrice.selling + type.num_of_products * price.selling.pricePerProduct;
            continue;
        }

        //regular
        edPrice = type.regular;
        if (edPrice) {
            // cost += edPrice.cost_price * type.num_of_products;
            // selling += edPrice.selling_price * type.num_of_products;
            cost += edPrice.cost_price + type.num_of_products * price.cost.pricePerProduct;
            selling += edPrice.selling_price + type.num_of_products * price.selling.pricePerProduct;
            continue;
        }
    }

    return { cost, selling };
};

export let calculateOutletBranchValue = (outletBranch, dateRange) => {
    //total price of 1 outlet branch during the whole project
    let totalPrice = {
        cost: {
            total: 0,
            general_merchandising: 0,
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: 0,
            extra_display: 0,
            handover: 0,
            bonus: 0,
        },
        selling: {
            total: 0,
            general_merchandising: 0,
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: 0,
            extra_display: 0,
            handover: 0,
            bonus: 0,
        },
        bonus: 0,
        totalBonus: 0,
        coefficient: 1,
        handover: 0,
    };
    //if this outlet does not have product assigned
    if (!outletBranch.has_products) {
        return totalPrice;
    }

    const { startDate, endDate } = dateRange;

    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const workingDaysIDs = getWorkingDaysIDs(outletBranch.merchandising_days);
    let workingDays = [];
    if (outletBranch.merchandising_days.sun.selected) workingDays.push(weekday[0]);
    if (outletBranch.merchandising_days.mon.selected) workingDays.push(weekday[1]);
    if (outletBranch.merchandising_days.tue.selected) workingDays.push(weekday[2]);
    if (outletBranch.merchandising_days.wed.selected) workingDays.push(weekday[3]);
    if (outletBranch.merchandising_days.thu.selected) workingDays.push(weekday[4]);
    if (outletBranch.merchandising_days.fri.selected) workingDays.push(weekday[5]);
    if (outletBranch.merchandising_days.sat.selected) workingDays.push(weekday[6]);

    if (workingDays.length === 0) return totalPrice;

    //loop through between start date and end date
    let totalVisits = 0;

    //check if the outlet needs handover
    let handoverBonus = 0;
    let ishandoverTask = false;
    if (outletBranch.handover) {
        handoverBonus = 10;
        ishandoverTask = true;
    }

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        try {
            //get day name
            const day = weekday[date.getDay()];
            const index = workingDays.findIndex((workingDay) => workingDay === day);
            const dayID = workingDaysIDs.find((dayID) => dayID === WEEKDAYS[day]);
            //if it is not a working day
            if (index === -1) continue;
            totalVisits++;

            let price = {
                cost: {
                    general_merchandising: 0,
                    task_per_hour: 0,
                    task_per_minute: 0,
                    warehouse: 0,
                    pricePerProduct: 0,
                },
                selling: {
                    general_merchandising: 0,
                    task_per_hour: 0,
                    task_per_minute: 0,
                    warehouse: 0,
                    pricePerProduct: 0,
                },
                coefficient: 1,
            };

            //* calculate regular price
            price = calculateNormalPrice(date, dayID, outletBranch);

            //* calculate Extra Display price if any
            let edPrice = { cost: 0, selling: 0 };
            for (const contractPrice of outletBranch.extra_display_contracts_prices) {
                const contract = contractPrice.contract;
                // if this day is outside the contract's period, skip
                if (contract.date_from.toDate() > date || contract.date_to.toDate() < date) continue;

                const tempPrice = calculateEDPrice(contractPrice, date, dayID, outletBranch.seasons, price);
                edPrice.cost += tempPrice.cost;
                edPrice.selling += tempPrice.selling;
            }

            //* store coefficient if total price is only for 1 day
            totalPrice.coefficient = price.coefficient;

            //* total price per 1 visit
            totalPrice.selling = {
                ...totalPrice.selling,
                general_merchandising: totalPrice.selling.general_merchandising + price.selling.general_merchandising,
                warehouse: totalPrice.selling.warehouse + price.selling.warehouse,
                task_per_minute: totalPrice.selling.task_per_minute + price.selling.task_per_minute,
                task_per_hour: totalPrice.selling.task_per_hour + price.selling.task_per_hour,
                extra_display: totalPrice.selling.extra_display + edPrice.selling,
                total:
                    totalPrice.selling.total +
                    price.selling.general_merchandising +
                    price.selling.warehouse +
                    // price.selling.task_per_minute +
                    // price.selling.task_per_hour +
                    edPrice.selling,
            };
            totalPrice.cost = {
                ...totalPrice.cost,
                general_merchandising: totalPrice.cost.general_merchandising + price.cost.general_merchandising,
                warehouse: totalPrice.cost.warehouse + price.cost.warehouse,
                task_per_minute: totalPrice.cost.task_per_minute + price.cost.task_per_minute,
                task_per_hour: totalPrice.cost.task_per_hour + price.cost.task_per_hour,
                extra_display: totalPrice.cost.extra_display + edPrice.cost,
                total:
                    totalPrice.cost.total +
                    price.cost.general_merchandising +
                    price.cost.warehouse +
                    // price.cost.task_per_minute +
                    // price.cost.task_per_hour +
                    edPrice.cost,
            };

            // if (projectID === "M-105-2104-123" && ishandoverTask) {
            if (ishandoverTask) {
                totalPrice.selling.handover += handoverBonus;
                totalPrice.selling.total += handoverBonus;

                totalPrice.cost.handover += handoverBonus;
                totalPrice.cost.total += handoverBonus;

                totalPrice.handover = handoverBonus;
                ishandoverTask = false;
            }

            // totalPrice.selling.total += price.selling.general_merchandising + price.selling.warehouse + edPrice.selling;
            // totalPrice.cost.total += price.cost.general_merchandising + price.cost.warehouse + edPrice.cost;
        } catch (error) {
            console.log(error);
            return totalPrice;
        }
    }

    //* adding bonus or not
    const bonus = 5;
    if (totalPrice.cost.general_merchandising / totalVisits < 10) {
        totalPrice.bonus = bonus;
        totalPrice.totalBonus = bonus * totalVisits;

        totalPrice.cost.bonus += bonus * totalVisits;
        totalPrice.cost.total += totalPrice.totalBonus;
        totalPrice.selling.total += totalPrice.totalBonus;
        totalPrice.selling.bonus += bonus * totalVisits;
    }

    return totalPrice;
};

export let getProjectTotalPrice = async (project, dateRange = null, projectID = null) => {
    let totalPrice = {
        cost: {
            total: 0,
            general_merchandising: 0,
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: 0,
            extra_display: 0,
        },
        selling: {
            total: 0,
            general_merchandising: 0,
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: 0,
            extra_display: 0,
        },
        handover: 0,
        totalBonus: 0,
    };

    //if date range = null, get total price for the whole project period
    if (!dateRange) {
        //go through every supplier branch involved in the project and process the working days to calculate the total price
        for (const supplierBranch of project.supplierBranches) {
            //for each outlet branch that is dealing with a supplier branch
            for (const outletBranch of supplierBranch.outletBranches) {
                for (const schedule of outletBranch.schedules) {
                    const price = schedule.data.total_price;
                    if (!price) continue;

                    totalPrice.selling = {
                        general_merchandising:
                            totalPrice.selling.general_merchandising + price.selling.general_merchandising,
                        warehouse: totalPrice.selling.warehouse + price.selling.warehouse,
                        task_per_minute: totalPrice.selling.task_per_minute + price.selling.task_per_minute,
                        task_per_hour: totalPrice.selling.task_per_hour + price.selling.task_per_hour,
                        extra_display: totalPrice.selling.extra_display + price.selling.extra_display,
                        total: totalPrice.selling.total + price.selling.total,
                    };

                    totalPrice.cost = {
                        general_merchandising: totalPrice.cost.general_merchandising + price.cost.general_merchandising,
                        warehouse: totalPrice.cost.warehouse + price.cost.warehouse,
                        task_per_minute: totalPrice.cost.task_per_minute + price.cost.task_per_minute,
                        task_per_hour: totalPrice.cost.task_per_hour + price.cost.task_per_hour,
                        extra_display: totalPrice.cost.extra_display + price.cost.extra_display,
                        total: totalPrice.selling.total + price.selling.total,
                    };

                    totalPrice.totalBonus += price.totalBonus;
                    totalPrice.handover += price.handover;

                    // totalPrice += outletBranch.total_price ? outletBranch.total_price.selling || 0 : 0;
                }
            }
        }
    } else {
        for (const supplierBranch of project.supplierBranches) {
            for (const outletBranch of supplierBranch.outletBranches) {
                for (const schedule of outletBranch.schedules) {
                    const price = calculateOutletBranchValue(schedule.data, dateRange);

                    totalPrice.selling = {
                        general_merchandising:
                            totalPrice.selling.general_merchandising + price.selling.general_merchandising,
                        warehouse: totalPrice.selling.warehouse + price.selling.warehouse,
                        task_per_minute: totalPrice.selling.task_per_minute + price.selling.task_per_minute,
                        task_per_hour: totalPrice.selling.task_per_hour + price.selling.task_per_hour,
                        extra_display: totalPrice.selling.extra_display + price.selling.extra_display,
                        total: totalPrice.selling.total + price.selling.total,
                    };
                    totalPrice.cost = {
                        general_merchandising: totalPrice.cost.general_merchandising + price.cost.general_merchandising,
                        warehouse: totalPrice.cost.warehouse + price.cost.warehouse,
                        task_per_minute: totalPrice.cost.task_per_minute + price.cost.task_per_minute,
                        task_per_hour: totalPrice.cost.task_per_hour + price.cost.task_per_hour,
                        extra_display: totalPrice.cost.extra_display + price.cost.extra_display,
                        total: totalPrice.cost.total + price.cost.total,
                    };

                    totalPrice.totalBonus += price.totalBonus;
                    totalPrice.handover += price.handover;
                }
            }
        }
    }

    return totalPrice;
};

export let getWorkingDaysIDs = (merchandising_days) => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let workingDaysIDs = [];
    if (merchandising_days.sun.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[0]]);
    }
    if (merchandising_days.mon.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[1]]);
    }
    if (merchandising_days.tue.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[2]]);
    }
    if (merchandising_days.wed.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[3]]);
    }
    if (merchandising_days.thu.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[4]]);
    }
    if (merchandising_days.fri.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[5]]);
    }
    if (merchandising_days.sat.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[6]]);
    }

    return workingDaysIDs;
};
