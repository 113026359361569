import { Route, Switch } from "react-router-dom";
import { PrivateRoute, LagacyRoute, PublicRoute } from "./components";
import { AuthContextProvider } from "./contexts/auth-context";
import "./core-ui/core-styles/css/global.module.css";
import "./core-ui/core-styles/scss/material-kit-react.scss";
import { SignIn, UpdateAccountInfo, ForgotPassword, ResetPassword, Subscriptions, VideoTutorials } from "./pages";
import { AccountInfo, Guide } from "./pages-lagacy";
import PATHS from "./constants/paths";
import { GeolocationContextProvider } from "./contexts/geolocation-context";
// import ProductsImporter from "./pages/products-importer";

function App() {
    return (
        <GeolocationContextProvider>
            <AuthContextProvider>
                <Switch>
                    <PublicRoute path={PATHS.SIGN_IN} exact>
                        <SignIn />
                    </PublicRoute>
                    <PublicRoute path={PATHS.FORGOT_PASSWORD} exact>
                        <ForgotPassword />
                    </PublicRoute>
                    <PublicRoute path={PATHS.RESET_PASSWORD} exact>
                        <ResetPassword />
                    </PublicRoute>

                    {/* Account */}
                    <LagacyRoute path={PATHS.ACCOUNT_INFO} exact>
                        <AccountInfo />
                    </LagacyRoute>
                    <PrivateRoute path={PATHS.UPDATE_ACCOUNT_INFO} exact>
                        <UpdateAccountInfo />
                    </PrivateRoute>

                    {/* <PrivateRoute path={"/import-products"} exact>
                        <ProductsImporter />
                    </PrivateRoute> */}

                    {/* Subscription */}
                    <PrivateRoute path={PATHS.SUBSCRIPTIONS} exact>
                        <Subscriptions />
                    </PrivateRoute>

                    {/* Help */}
                    <LagacyRoute path={PATHS.HELP} exact>
                        <Guide />
                    </LagacyRoute>
                    <PrivateRoute path={PATHS.TUTORIALS} exact>
                        <VideoTutorials />
                    </PrivateRoute>

                    <PublicRoute path="/" exact>
                        <SignIn />
                    </PublicRoute>
                    <Route path="*">
                        <span>404: No Page Was Found.</span>
                    </Route>
                </Switch>
            </AuthContextProvider>
        </GeolocationContextProvider>
    );
}

export default App;
