import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    dashboardCard: {
        height: "300px",
    },
    cardHeader: {
        height: "50px",
    },
    title: {
        fontSize: "1rem",
    },
    cardContent: {
        height: "calc(100% - 50px)",
        width: "100%",
        padding: "0",
        paddingBottom: "0 !important",
    },
});

export default styles;
