import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    root: {
        // marginTop: 30,
        position: "relative",
        maxHeight: "250px",
    },
    picBeforeTitle: {
        position: "absolute",
        top: "0",
        left: "0",
        backgroundColor: "rgba(0,0,0,0.7)",
        color: "white",
        fontSize: ".8rem",
        padding: "10px",
        borderBottomRightRadius: "7px",
        pointerEvents: "none",
        zIndex: 100,
    },

    videoThumbnail: {
        width: "100%",
    },

    videoDuration: {
        position: "absolute",
        bottom: "10px",
        right: "10px",
        background: "black",
        color: "white",
        fontSize: "0.7rem",
        fontWeight: "bold",
        padding: "0px 3px",
    },

    description: {
        fontSize: "0.7rem",
    },

    cardHeader: {
        position: "relative",
        fontSize: "0.75rem",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",

        "&:hover span": {
            visibility: "visible",
        },
    },

    cardContent: {
        position: "relative",
        padding: 0,
        height: 156,
    },

    tooltip: {
        visibility: "hidden",
        // width: "120px",
        backgroundColor: "black",
        color: "#fff",
        textAlign: "center",
        borderRadius: "6px",
        padding: "5px 0",

        /* Position the tooltip */
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 100,
        pointerEvents: "none",
    },
    dateTime: {
        fontSize: "0.7rem",
    },
});

export default styles;
