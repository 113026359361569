import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { Autocomplete } from "@material-ui/lab";
import COLORS from "../../../constants/colors";

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.FRENSH_BLUE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.FRENSH_BLUE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
        },
    },
});

const AutocompleteWrapper = ({ name, options, label, ...otherProps }) => {
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();
    const rootStyle = useRootStyle();

    const handleChange = (e, value) => {
        setFieldValue(name, value ?? meta.initialValue);
    };

    const textFieldConfig = {
        name: name,
        label: label,
        variant: "outlined",
        classes: {
            root: rootStyle.root,
        },
    };

    const config = {
        ...field,
        ...otherProps,
        options: options,
        getOptionLabel: (opt) => opt.label,
        getOptionSelected: (opt, selected) => opt.value === selected.value,
        fullWidth: true,
        onChange: handleChange,
        renderInput: (params) => <TextField {...textFieldConfig} {...params} />,
    };

    if (meta && meta.touched && meta.error) {
        textFieldConfig.error = true;
        textFieldConfig.helperText = meta.error;
    }

    return <Autocomplete {...config} />;
};

export default AutocompleteWrapper;
