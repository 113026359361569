const VIDEOS_INFO = [
    {
        id: 1,
        title: "01 - [Data Entry] Introduction",
        description: "Introduction to the system.",
        url: "https://vimeo.com/732009475",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 2,
        title: "02 - [Data Entry] Branches",
        description: "Add, edit and manage your comapny branches.",
        url: "https://vimeo.com/733072844",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 3,
        title: "03 - [Data Entry] Outlets Selection",
        description: "Select outlet companies you want to deal with.",
        url: "https://vimeo.com/733072984",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 4,
        title: "04 - [Data Entry] Link Your Branch With Outlet Branches",
        description:
            "Determine your market coverage by linking Your company branch with multiple outlet branches (supermarkets).",
        url: "https://vimeo.com/733073031",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 5,
        title: "05 - [Data Entry] Employees Management",
        description: "Add, edit and manage your employees accounts.",
        url: "https://vimeo.com/733073089",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 6,
        title: "06 - [Data Entry] Products Management Part 1",
        description: "Manage your product data.",
        url: "https://vimeo.com/733073139",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 7,
        title: "07 - [Data Entry] Products Management Part 2",
        description: "More details to manage the packing, expiry, near expiry ...etc of the product",
        url: "https://vimeo.com/733073217",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 8,
        title: "08 - [Data Entry] Link Your Products With Outlets Part 1",
        description: "Register your products into the outlet companies you chose from the previous steps",
        url: "https://vimeo.com/733073372",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 9,
        title: "09 - [Data Entry] Link Your Products With Outlets Part 2",
        description: "Register your products into the outlet companies you chose from the previous step",
        url: "https://vimeo.com/733073394",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 10,
        title: "10 - [Data Entry] Extra Display Contracts Management",
        description: "Add, edit and manage your extra display contracts",
        url: "https://vimeo.com/733073420",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "data entry",
    },
    {
        id: 11,
        title: "11 - [Planning] Project Creation",
        description: "Create and setup your project with basic information.",
        url: "https://vimeo.com/733073467",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "planning",
    },
    {
        id: 12,
        title: "12 - [Planning] Project Coverage & Management Part 1",
        description: "Determine Project Coverage and add more details in management tab.",
        url: "https://vimeo.com/733073516",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "planning",
    },
    {
        id: 13,
        title: "13 - [Planning] Project Coverage & Management Part 2",
        description: "add more details in management tab.",
        url: "https://vimeo.com/733073655",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "planning",
    },
    {
        id: 14,
        title: "14 - [Planning] Route Plan Management Part 1",
        description: "Manage your route plan.",
        url: "https://vimeo.com/733073712",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "planning",
    },
    {
        id: 15,
        title: "15 - [Planning] Route Plan Management Part 2",
        description: "More Details about route plan management.",
        url: "https://vimeo.com/733073801",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "planning",
    },
    {
        id: 16,
        title: "16 - [Planning] Project Confirmation & Visits Publish",
        description:
            "Confirm your project after setting up everything so that the system will use the project as blueprint to publish your visit throughout the lifespan of your project.",
        url: "https://vimeo.com/733073844",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "planning",
    },
    {
        id: 17,
        title: "17 - [Operations] Visit Management",
        description: "Manage and monitor your visits.",
        url: "https://vimeo.com/733073902",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "operations",
    },
    {
        id: 18,
        title: "18 - [Operations] Reference Images",
        description: "Get to know the reference images feature and its benefits.",
        url: "https://vimeo.com/733074769",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "operations",
    },
    {
        id: 19,
        title: "19 - [Operations] Visit Details",
        description: "View More details of a visit and its reports.",
        url: "https://vimeo.com/733074932",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "operations",
    },
    {
        id: 20,
        title: "21 - [Operations] Reports",
        description: "View some of the reports that are auto generated by the system.",
        url: "https://vimeo.com/733075314",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "operations",
    },

    // Mobile
    {
        id: 21,
        title: "01 - Registration",
        description: "Registration Process in eMaham Merchandiser app.",
        url: "https://vimeo.com/733320530",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "mobile",
    },
    {
        id: 22,
        title: "02 - Profile",
        description: "Merchandiser's Information and general setting of the app.",
        url: "https://vimeo.com/733320441",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "mobile",
    },
    {
        id: 23,
        title: "03 - Route Plan & Visit Info",
        description:
            "View merchandiser's route plan of each day and see the visit's details before starting the visit.",
        url: "https://vimeo.com/733320133",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "mobile",
    },
    {
        id: 24,
        title: "04 - [Visit Process] Before Display",
        description: "First Stage once the merchandiser begins his visit.",
        url: "https://vimeo.com/733320330",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "mobile",
    },

    {
        id: 25,
        title: "05 - [Visit Process] Warehouse",
        description: "Second Stage of the merchandiser's visit.",
        url: "https://vimeo.com/733320231",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "mobile",
    },
    {
        id: 26,
        title: "06 - [Visit Process] After Display",
        description: "Final Stage of the merchandiser's visit.",
        url: "https://vimeo.com/733320302",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "mobile",
    },
    {
        id: 27,
        title: "07 - [Visit Process] Ending Visit",
        description: "How To end the visit and provide reasons in case the work is incomplete.",
        url: "https://vimeo.com/733320404",
        timestamp: new Date(2022, 6, 19, 9, 20, 48),
        section: "mobile",
    },
];

export default VIDEOS_INFO;
