import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../constants/constants-lagacy";



//styles
const useStyles = makeStyles({
    tableContainer: {
        maxHeight: "700px",
        overflow: "auto",
        width: "90%",
        minWidth: "560px",
    },
    table: {
        minWidth: "560px",
        borderCollapse: "collapse",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "14px",

        "& th": {
            // border: '1px solid black',
            border: 0,
            color: COLORS.font,
            backgroundColor: COLORS.primary,
            // backgroundColor: "#8b8b8b",
            textAlign: "center",
            fontSize: "1.2em",
            padding: 0,
            whiteSpace: "nowrap",
            zIndex: 100,
        },
        "& th:last-child": {
            paddingRight: 0,
        },
        "& td": {
            position: "relative",
            textAlign: "center",
            fontWeight: "700",
            fontSize: "1.2em",
            padding: "14px 16px",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
        },
        "& $textLeft": {
            textAlign: "left",
        },
    },
    textLeft: {
        textAlign: "left",
    },
    headerRow: {
        backgroundColor: COLORS.primary,
    },
    header: {
        position: "sticky",
        top: "0px",
    },
    secondHeader: {
        position: "sticky",
        top: "24px",
    },

    imageIcon: {
        color: "#606060",
        cursor: "pointer",
        position: "absolute",
        right: 0,
        "&:hover": {
            color: "#1a1a1a",
        },
    },
    blueCell: {},
    rowEven: {
        backgroundColor: "#eef5ff",
    },
});

//header data creation
function createHeaderData(name, attributes) {
    return { name, attributes };
}

//headers data
const firstRowheaders = [
    createHeaderData("", { rowSpan: 2 }),
    createHeaderData("Product No.", { rowSpan: 2 }),
    createHeaderData("Barcode", { rowSpan: 2 }),
    createHeaderData("Product", { rowSpan: 2 }),
    // createHeaderData("Available", { rowSpan: 2 }),
    // createHeaderData("Packing", { colSpan: 2 }),
    createHeaderData("Qty on", { colSpan: 2 }),
    createHeaderData("W/H Draw", { rowSpan: 2 }),
    // createHeaderData("Quantity Added", { colSpan: 2 }),
    createHeaderData("Qty Dspl'ed", { colSpan: 2 }),
];
const secondRowHeaders = [
    // createHeaderData("Former", {}),
    // createHeaderData("Conf.", {}),
    createHeaderData("Dspl", {}),
    createHeaderData("E.D.", {}),
    // createHeaderData("Draw", {}),
    // createHeaderData("Shelf", {}),
    // createHeaderData("E. Display", {}),
    createHeaderData("Dspl", {}),
    createHeaderData("E.D.", {}),
];

const ReportTable = (props) => {
    const classes = useStyles();

    let tableBody = (
        <TableRow>
            <TableCell align={"center"} colSpan={11}>
                Empty
            </TableCell>
        </TableRow>
    );
    //if task object is not empty
    if (Object.keys(props.task).length !== 0 && props.task.constructor === Object) {
        let counter = 1;
        const tasksDetails = props.task.details;
        tableBody = tasksDetails.map((taskDetails, index) => {
        return (
            <TableRow key={`tdr-${index}`} className={index % 2 === 0 ? classes.rowEven : classes.row}>
            <TableCell className={`${classes.blueCell}`}>{counter++}</TableCell>
                <TableCell className={`${classes.blueCell}`}>{taskDetails.product.code}</TableCell>
                <TableCell className={`${classes.blueCell}`}>{taskDetails.product.barcode}</TableCell>
                <TableCell className={`${classes.blueCell} ${classes.textLeft}`}>
                    {taskDetails.product.name}
                    {/* {taskDetails.images.length !== 0 ? (
                        <PhotoAlbumIcon className={classes.imageIcon} onClick={() => props.displayGallery(taskDetails.images)} />
                    ) : null} */}
                </TableCell>
                {/* <TableCell className={`${classes.blueCell}`}>
                    {taskDetails.shelfQuantity !== 0 ||
                    taskDetails.displayQuantity !== 0 ||
                    taskDetails.shelfTotal !== 0 ||
                    taskDetails.displayTotal !== 0
                        ? "Yes"
                        : "No"}
                </TableCell> */}
                {/* <TableCell className={`${classes.blueCell}`}>-</TableCell>
                <TableCell className={`${classes.blueCell}`}>-</TableCell> */}
                <TableCell>{taskDetails.shelfQuantity !== 0 ? taskDetails.shelfQuantity : "-"}</TableCell>
                <TableCell>{taskDetails.displayQuantity !== 0 ? taskDetails.displayQuantity : "-"}</TableCell>
                <TableCell className={`${classes.blueCell}`}>
                    {/* {taskDetails.warehouseDraw !== 0 ? `${taskDetails.warehouseDraw} ${taskDetails.product.packing.packing}` : "-"} */}
                    {taskDetails.warehouseDraw ? `${taskDetails.warehouseDraw}` : "-"}
                </TableCell>
                {/* <TableCell>{taskDetails.shelfQuantiyAdded !== 0 ? taskDetails.shelfQuantiyAdded : "-"}</TableCell>
                <TableCell>{taskDetails.displayQuantityAdded !== 0 ? taskDetails.displayQuantityAdded : "-"}</TableCell> */}
                <TableCell className={`${classes.blueCell}`}>
                    {taskDetails.shelfTotal !== 0 ? taskDetails.shelfTotal : "-"}
                </TableCell>
                <TableCell className={`${classes.blueCell}`}>
                    {taskDetails.displayTotal !== 0 ? taskDetails.displayTotal : "-"}
                </TableCell>
            </TableRow>
        )});
    }

    return (
        // <div className={classes.tableContainer}>

        <Table className={classes.table}>
            {/* headers */}
            <TableHead>
                <TableRow className={classes.headerRow}>
                    {firstRowheaders.map((header, index) => (
                        <TableCell key={`thr1-${index}`} {...header.attributes} className={classes.header}>
                            {header.name}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.headerRow}>
                    {secondRowHeaders.map((header, index) => (
                        <TableCell key={`thr2-${index}`} {...header.attributes} className={classes.secondHeader}>
                            {header.name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            {/* Body */}
            <TableBody>
                {props.loading ? (
                    <TableRow>
                        <TableCell align={"center"} colSpan={11}>
                            <CircularProgress />
                        </TableCell>
                    </TableRow>
                ) : (
                    tableBody
                )}
            </TableBody>
        </Table>
        // </div>
    );
};

export default ReportTable;
