import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import PATHS from "../../../constants/paths";
import { useAuth } from "../../../contexts/auth-context";
import { useGeolocation } from "../../../contexts/geolocation-context";
import { Button } from "../../../core-ui/custom";
// import { logSignOut } from "../../../services/firestore/Log";
import styles from "./styles";
const NavigationAccountContent = (props) => {
    const classes = styles();
    const accountContentRef = useRef();
    const { setOpen } = props;

    const { pathname } = useLocation();
    const { position } = useGeolocation();
    const { signout, userData } = useAuth();

    const handleSignOut = async () => {
        try {
            // const userID = `${userData.uid}`;
            // await logSignOut(userID, position, pathname);
            await signout();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const handleOutsideAccountContentClick = (e) => {
            if (accountContentRef && !accountContentRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideAccountContentClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideAccountContentClick);
        };
    }, [setOpen]);

    return (
        <div className={classes.accountContent} ref={accountContentRef}>
            <ul>
                <li className={classes.userInfo}>
                    Role: <b>{userData.user_type}</b>
                </li>
                {/* <li>
                    <Link to={PATHS.ACCOUNT_INFO}>Account Info</Link>
                </li>
                <li>
                    <Link to={PATHS.SUBSCRIPTIONS}>Subscription</Link>
                </li> */}
            </ul>
            <ul>
                <li>
                    <Button buttonVariant="transparent" onClick={handleSignOut}>
                        Sign Out
                    </Button>
                </li>
            </ul>
        </div>
    );
};

export default NavigationAccountContent;
