import { firestore as db } from "../firebase";

export let getOutlet = async (id) => {
    let outlet = null;
    try {
        outlet = await db.collection("Outlet").doc(`${id}`).get();
    } catch (error) {
        console.log(error);
    }

    return outlet;
};

export let getOutlets = async (IDs) => {
    let outlets = [];

    try {
        outlets = await Promise.all(IDs.map(getOutlet));
    } catch (error) {
        console.log(error);
    }

    return outlets;
};

export let getAllOutlets = async () => {
    let outlets = null;
    try {
        outlets = (await db.collection("Outlet").get()).docs;
        return outlets;
    } catch (error) {
        throw error;
    }
};
