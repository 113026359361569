import PATHS from "./paths";

const HOME = {
    link: PATHS.DASHBOARD,
    label: "Dashboard",
    // menu: [],
};

const HELP_MENU = {
    label: "Help",
    menu: [
        { label: "Getting Started", link: PATHS.HELP },
        { label: "Tutorials", link: PATHS.TUTORIALS },
    ],
};

// const NAV_MENU = [{ ...SERVICES_MENU }];
const NAV_MENU = [];

export default NAV_MENU;
