import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { COLORS } from "../../constants/constants-lagacy";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        width: "100%",
    },
    card: { flex: 1 },
    cardHeader: {
        padding: theme.spacing(1, 2),
        backgroundColor: COLORS.background,
        color: COLORS.primary,
    },
    filterInput: {
        width: "100%",
        height: "100%",
    },
    list: {
        // width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    listItemPrimary: {
        fontWeight: "600",
    },

    listItemSecondary: {
        color: COLORS.accent,
    },
}));

//return elements that exist in a but no in b
function not(a, b) {
    return a.filter((objA) => b.findIndex((objB) => objA.id === objB.id) === -1);
}

//return elements that exist in both a and b
function intersection(a, b) {
    return a.filter((objA) => b.findIndex((objB) => objA.id === objB.id) !== -1);
}

//return all elements from a and b with no duplicates
function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function TransferList(props) {
    const classes = useStyles(props);
    //leftList and rightList are array of objects that must contain {id, prrimaryLabel, hidden, secondaryLabel: null}
    const { leftList, setLeft, leftTitle = "choices", onLeftListFilter } = props.left;
    const { rightList, setRight, rightTitle = "chosen", onRightListFilter } = props.right;
    const { checkedList, setChecked } = props.checked;
    const disabled = props.disabled;

    const leftChecked = intersection(checkedList, leftList);
    const rightChecked = intersection(checkedList, rightList);

    const handleToggle = (toggledObject) => () => {
        if (disabled) return;

        const currentIndex = checkedList.findIndex((obj) => obj.id === toggledObject.id);
        const newChecked = [...checkedList];

        //if not checked, push. otherwise, delete
        if (currentIndex === -1) {
            newChecked.push(toggledObject);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checkedList, items).length;

    const handleToggleAll = (items) => () => {
        if (disabled) return;
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checkedList, items));
        } else {
            setChecked(union(checkedList, items));
        }
    };

    //transfer left items to right list
    const handleCheckedRight = () => {
        if (disabled) return;
        //reset hidden property on transferring items
        leftChecked.forEach((item) => (item.hidden = false));

        setRight(rightList.concat(leftChecked));
        setLeft(not(leftList, leftChecked));
        setChecked(not(checkedList, leftChecked));
    };

    //transfer left items to left list
    const handleCheckedLeft = () => {
        if (disabled) return;
        //reset hidden property on transferring items
        rightChecked.forEach((item) => (item.hidden = false));

        setLeft(leftList.concat(rightChecked));
        setRight(not(rightList, rightChecked));
        setChecked(not(checkedList, rightChecked));
    };

    const customList = (title, items, onListFilter) => {
        //filter only not hidden
        const shownItems = items.filter((item) => !item.hidden);
        return (
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(shownItems)}
                            checked={numberOfChecked(shownItems) === shownItems.length && shownItems.length !== 0}
                            indeterminate={
                                numberOfChecked(shownItems) !== shownItems.length && numberOfChecked(shownItems) !== 0
                            }
                            disabled={shownItems.length === 0 || disabled}
                            inputProps={{ "aria-label": "all items selected" }}
                        />
                    }
                    title={title}
                    subheader={`${numberOfChecked(shownItems)}/${shownItems.length} selected`}
                />
                <Divider />
                {/* filter field */}
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    className={classes.filterInput}
                    onChange={(e) => onListFilter(e, items)}
                />
                <List className={classes.list} dense component="div" role="list">
                    {shownItems.map((object) => {
                        const labelId = `transfer-list-all-item-${object.id}-label`;

                        return (
                            <ListItem
                                key={`tansferList-item-${object.id}`}
                                role="listitem"
                                button
                                onClick={handleToggle(object)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        disabled={disabled}
                                        checked={checkedList.findIndex((obj) => obj.id === object.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={object.primaryLabel}
                                    secondary={object.secondaryLabel}
                                    primaryTypographyProps={{ className: classes.listItemPrimary }}
                                    secondaryTypographyProps={{ className: classes.listItemSecondary }}
                                />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Card>
        );
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item className={classes.card}>
                {customList(leftTitle, leftList, onLeftListFilter)}
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0 || disabled}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0 || disabled}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item className={classes.card}>
                {customList(rightTitle, rightList, onRightListFilter)}
            </Grid>
        </Grid>
    );
}
