import { storage } from "./firebase";

export const uploadImage = async (path, file, name = null, storageWorker = null) => {
    try {
        name = name ?? file.name;
        const ref = storageWorker ? storageWorker.ref(path).child(name) : storage.ref(path).child(name);
        await ref.put(file);
        const url = await ref.getDownloadURL();

        return url;
    } catch (error) {
        throw error;
    }
};

export const uploadExcel = async (path, file, name = null) => {
    try {
        name = name ?? file.name;
        const ref = storage.ref(path).child(name);
        await ref.put(file);
        const url = await ref.getDownloadURL();

        return url;
    } catch (error) {
        throw error;
    }
};
