import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import { withStyles } from "@material-ui/core/styles";
import {
    getMerchandiserTasksByDate,
    getMerchandiserTaskHeadersByDate,
    getTask,
} from "../../../services/firestore/Task";
import { getSupplierBranchInfo } from "../../../services/firestore/Supplier_Branch";
import { getCity } from "../../../services/firestore/City";
import {
    getMerchandiserName,
    getMerchandisersListByTask,
    getOutsourcedMerchandisers,
} from "../../../services/firestore/Merchandiser";
import { formatDate, formatTime } from "../../../helpers/date-utils";
import { COLORS, getStatusLabel, MERCH_CONTRACT_STATUS, TASKS_STATUS } from "../../../constants/constants-lagacy";
import { getOutletBranch } from "../../../services/firestore/Outlet_Branch";
import { getSupplier } from "../../../services/firestore/Supplier";
import { Button, Card, darken, Grid, TextField } from "@material-ui/core";

import ContentHeader from "../../../components-lagacy/Header/ContentHeader";

import TransitionAlert from "../../../core-ui/custom/transition-alert";
import ProductExpiryAndDamagePdf from "../../../components-lagacy/PDF/product-expiry-and-damage";
import PDFButton from "../../../components-lagacy/Button/PDFButton";
import { getUser } from "../../../services/firestore/User";
import { getScheduleByTask } from "../../../services/firestore/Project";
// import { getMerchProvider } from "../../../services/firestore/MP";
import VisitInfoSection from "./VisitInfoSection";

import { firestore as db } from "../../../services/firebase";
import { queryMerchandiserExclusivityContracts } from "../../../services/firestore/Merchandiser_Exclusivity_Contract";
import { arrayRemoveDuplicates } from "../../../helpers/array-helpers";
import AuthContext from "../../../contexts/auth-context";

const useStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "90px",
    },
    contentCard: {
        padding: "10px",
        width: "100%",
    },
    loader: {
        marginTop: "35px",
    },
    dateInput: {
        margin: 0,
        height: "100%",
    },
    saveButton: {
        width: "100%",
        // marginTop: 14,
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: COLORS.submit,
        color: "#fff",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
            color: "#fff",
        },
    },
    mdrButton: {
        width: "100%",
        textTransform: "none",
        backgroundColor: COLORS.primary,
        color: COLORS.font,
        // marginTop: 14,
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        border: "2",
        fontWeight: "400",
        "&:hover": {
            backgroundColor: darken(COLORS.primary, 0.2),
            color: COLORS.font,
        },
    },

    pdfBtn: {
        textTransform: "none",
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: COLORS.accent,
        color: COLORS.font,
        "&:hover": {
            backgroundColor: darken(COLORS.accent, 0.2),
            color: COLORS.font,
        },
    },

    disabledBtn: {
        backgroundColor: "#c9c9c9",
        color: "#00000042",
    },

    buttons: {
        margin: "10px 0",
    },
    statusBtn: {
        textTransform: "none",
        borderBottom: "3px solid #00000000",
        borderTop: "3px solid #00000000",
    },
    remarksContainer: {
        marginTop: "10px",
    },
    remarks: {
        flex: 1,
    },
    stickyAlert: {
        position: "sticky",
        top: 10,
        zIndex: 10,
    },
});

class VisitDetailsContent extends React.Component {
    static contextType = AuthContext;

    state = {
        loading: true,
        supplier: null,
        username: null,
        projectID: "",
        city: "",
        supplierBranch: "",
        outletBranch: "",
        date: "",
        duration: "",
        actualWorkTime: "",
        warehouseWorkTime: "",
        receivedAt: "",
        remarks: "",

        disableMerchandiser: true,
        merchandisersList: [],
        selectedMerchandiser: { label: "-", value: -1 },

        disableStatus: true,
        statusList: [],
        selectedStatus: { label: "-", value: -1 },

        task: null,

        alertOpened: false,
        alertMsg: "",
        alertProps: {
            variant: "filled",
            severity: "success",
        },

        productRemovalExist: false,
        products: [],
        exclusivityContracts: [],
    };

    classes = this.props.classes;
    type = this.props.type;

    init = async () => {
        try {
            const task = (await getTask(this.props.taskID)).data();

            let requests = [
                getCity(`${task.city_id}`),
                getSupplierBranchInfo(`${task.supplier_branch_id}`),
                getOutletBranch(`${task.outlet_branch_id}`),
                getMerchandisersListByTask(task),
                getOutsourcedMerchandisers(`${task.supplier_id}`),
                getSupplier(`${task.supplier_id}`),
                // getMerchProvider(task.mp_id),
                getUser(this.props.uid),
            ];

            if (task.only_header) {
                requests.push(getScheduleByTask(task));
                requests.push(
                    queryMerchandiserExclusivityContracts([
                        { key: "supplier_id", operator: "==", value: `${task.supplier_id}` },
                        { key: "end_state", operator: ">=", value: task.date_time_from.toDate() },
                        { key: "status", operator: "==", value: MERCH_CONTRACT_STATUS.ACTIVE },
                    ])
                );
            }

            let [
                city,
                supplierBranch,
                outletBranch,
                merchandisers,
                outsourcedMerchandisers,
                supplier,
                user,
                schedule,
                exclusivityContracts,
            ] = await Promise.all(requests);

            // doc to data
            [city, supplierBranch, outletBranch, merchandisers, supplier, user, schedule, exclusivityContracts] = [
                city,
                supplierBranch,
                outletBranch,
                merchandisers,
                supplier,
                user,
                schedule,
                exclusivityContracts,
            ].map((result) => {
                if (!result) return null;
                if (result instanceof Array) return result.map((d) => d.data());
                return result.data();
            });

            if (task.only_header) {
                if (!schedule) {
                    throw new Error(`Could not find schedule for the task ID: ${task.id}`);
                }

                // find if the assigned merchandiser is in the exclusivity contract
                const exclContracts = exclusivityContracts.filter((contract) => {
                    return contract.merchandisers.find((m) => m.id === task.uid);
                });

                // the closest contract by start date
                const exclContract = exclContracts.reduce((prev, curr) => {
                    if (!prev || prev.start_date > curr.start_date) {
                        return curr;
                    }
                    return prev;
                }, null);

                //if this task is outside the contract period
                if (
                    task.date_time_from.toDate() < exclContract?.start_date.toDate() ||
                    task.date_time_from.toDate() > exclContract?.end_date.toDate()
                ) {
                    task.uid = null;
                }

                const modifiedProps = task.modified_properties ?? [];
                task.uid = modifiedProps.indexOf("uid") !== -1 ? task.uid : schedule.merchandiser_id;
                task.state =
                    modifiedProps.indexOf("state") !== -1
                        ? task.state
                        : task.uid
                        ? TASKS_STATUS.BOOKED
                        : TASKS_STATUS.OPEN;
                task.duration = schedule.duration;
            }

            let products = await this.getProducts(this.props.taskID);
            let productRemovalExist = products.length > 0;

            let disableMerchandiser = true;
            let merchandisersList = merchandisers.map((merch) => ({
                value: merch.uid,
                label: getMerchandiserName(merch),
            }));

            // outsource merchandisers
            merchandisersList = merchandisersList.concat(
                outsourcedMerchandisers.map((merch) => ({
                    value: merch.info.uid,
                    label: getMerchandiserName(merch.info),
                }))
            );

            //remove duplicate merchandisers
            merchandisersList = arrayRemoveDuplicates(merchandisersList);

            let selectedMerchandiser = merchandisersList.find((merch) => merch.value === task.uid);
            selectedMerchandiser = selectedMerchandiser || null;
            let disableStatus = true;
            let statusList = [...this.state.statusList];

            if (task.state === TASKS_STATUS.BOOKED || task.state === TASKS_STATUS.CANCELED) {
                disableStatus = false;
                disableMerchandiser = false;
                statusList.unshift({
                    value: TASKS_STATUS.CANCELED,
                    label: getStatusLabel(TASKS_STATUS.CANCELED, this.type),
                });
                statusList.unshift({
                    value: TASKS_STATUS.BOOKED,
                    label: getStatusLabel(TASKS_STATUS.BOOKED, this.type),
                });
            } else if (task.state === TASKS_STATUS.OPEN) {
                statusList.unshift({ value: TASKS_STATUS.OPEN, label: getStatusLabel(TASKS_STATUS.OPEN, this.type) });
                disableMerchandiser = false;
            } else {
                statusList.unshift({ value: task.state, label: getStatusLabel(task.state, this.type) });
            }

            if (this.type === "outsource") {
                disableMerchandiser = true;
                disableStatus = true;
            }

            let selectedStatus = statusList.find((status) => status.value === task.state);

            this.setState({
                loading: false,
                supplier: supplier,
                // mp: mp,
                username: `${user.first_name} ${user.surname}`,
                projectID: task.project_id,
                city: city.En_name,
                supplierBranch: supplierBranch.En_name,
                outletBranch: outletBranch.En_short_name,
                date: formatDate(task.date_time_from.toDate()),
                duration: `${task.duration} Minutes`,
                actualWorkTime: this.getTimeAndDuration(task.started_at, task.finished_at),
                warehouseWorkTime: this.getTimeAndDuration(task.warehouse_started_at, task.warehouse_ended_at),
                receivedAt: task.received_at ? formatTime(task.received_at) : "-",
                remarks: task.remarks || "",
                disableMerchandiser: disableMerchandiser,
                merchandisersList: merchandisersList,
                selectedMerchandiser: selectedMerchandiser,

                disableStatus: disableStatus,
                statusList: statusList,
                selectedStatus: selectedStatus,
                task: task,

                productRemovalExist: productRemovalExist,
                products: products,
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    getProducts = async (taskID) => {
        try {
            const taskProducts = (
                await db.collection("Task_Details").where("task_id", "==", Number(taskID)).get()
            ).docs.map((doc) => doc.data());

            let productsIDs = taskProducts.reduce((arr, p) => {
                if (!arr.includes(p.product_id)) return [...arr, p.product_id];
                return arr;
            }, []);

            let productsInfo = await Promise.all(
                productsIDs.map(async (id) => {
                    return (await db.collection("Product").doc(id).get()).data();
                })
            );

            let productRemovalExist = false;
            let products = [];
            for (const tProduct of taskProducts) {
                let product = products.find((p) => p.id === tProduct.product_id);
                let newRemarks = tProduct.remarks ? tProduct.remarks : [];

                if (!product) {
                    const pInfo = productsInfo.find((p) => p.product_id === tProduct.product_id);
                    product = {
                        id: tProduct.product_id,
                        name: pInfo.En_name,
                        code: pInfo.product_number,
                        remarks: "",
                        removedFromShelf: {
                            isRemoved: false,
                            damaged: 0,
                            expired: 0,
                            near_expiration: [],
                            number_of_near_expiration: 0,
                            quantity_on_draw: 0,
                        },
                        removedFromExtraDisplay: {
                            isRemoved: false,
                            damaged: 0,
                            expired: 0,
                            near_expiration: [],
                            number_of_near_expiration: 0,
                            quantity_on_draw: 0,
                        },
                    };

                    products.push(product);
                }

                let oldRemarks = product.remarks.split(" - ");
                product.remarks = [...new Set([...oldRemarks, ...newRemarks])].join(" - ");

                if (tProduct.extra_display) {
                    let isRemoved = product.removedFromExtraDisplay.isRemoved || tProduct.removed;
                    let damaged = product.removedFromExtraDisplay.damaged + tProduct.removed_from.damaged;
                    let expired = product.removedFromExtraDisplay.expired + tProduct.removed_from.expired;
                    let numOfNearExp =
                        product.removedFromExtraDisplay.number_of_near_expiration +
                        tProduct.removed_from.number_of_near_expiration;

                    let nearExpiration = product.removedFromExtraDisplay.near_expiration;
                    if (tProduct.removed_from.number_of_near_expiration > 0) {
                        let obj2Arr = Object.values(tProduct.removed_from.near_expiration);
                        nearExpiration = [...product.removedFromExtraDisplay.near_expiration, ...obj2Arr];
                    }
                    product.removedFromExtraDisplay = {
                        isRemoved,
                        damaged,
                        expired,
                        number_of_near_expiration: numOfNearExp,
                        near_expiration: nearExpiration,
                    };
                } else {
                    let isRemoved = product.removedFromShelf.isRemoved || tProduct.removed;
                    let damaged = product.removedFromShelf.damaged + tProduct.removed_from.damaged;
                    let expired = product.removedFromShelf.expired + tProduct.removed_from.expired;
                    let numOfNearExp =
                        product.removedFromShelf.number_of_near_expiration +
                        tProduct.removed_from.number_of_near_expiration;

                    let nearExpiration = product.removedFromShelf.near_expiration;
                    if (tProduct.removed_from.number_of_near_expiration > 0) {
                        let obj2Arr = Object.values(tProduct.removed_from.near_expiration);
                        nearExpiration = [...product.removedFromShelf.near_expiration, ...obj2Arr];
                    }
                    product.removedFromShelf = {
                        isRemoved,
                        damaged,
                        expired,
                        number_of_near_expiration: numOfNearExp,
                        near_expiration: nearExpiration,
                    };
                }

                productRemovalExist = productRemovalExist || tProduct.removed;
            }

            products = products.filter(
                (product) => product.removedFromShelf.isRemoved || product.removedFromExtraDisplay.isRemoved
            );
            return products;
        } catch (error) {
            throw error;
        }
    };

    getTimeAndDuration = (start, end, withDuration = true) => {
        if (!start && !end) {
            return "-";
        }

        // let startString = start ? start.toDate().getHours() + ":" + start.toDate().getMinutes() : "";
        let startString = start ? formatTime(start.toDate(), false) : "";
        // let endString = end ? end.toDate().getHours() + ":" + end.toDate().getMinutes() : "";
        let endString = end ? formatTime(end.toDate(), false) : "";
        let duration = start && end ? Math.round(Math.abs(end.toDate() - start.toDate()) / 1000 / 60) : "-";

        let output =
            start && end && withDuration
                ? `${startString} - ${endString} / ${duration} Minutes`
                : `${startString} - ${endString}`;
        return output;
    };

    statusChanged = (e, value) => {
        this.setState({ selectedStatus: value });
    };

    merchChanged = (e, value) => {
        console.log(value);
        this.setState({ selectedMerchandiser: value });
    };

    remarksChanged = (e) => {
        this.setState({ remarks: e.target.value });
    };

    save = async () => {
        try {
            this.setState({ loading: true });

            let merchandiserID = this.state.selectedMerchandiser ? this.state.selectedMerchandiser.value : null;
            let task = { ...this.state.task };
            let status = this.state.selectedStatus.value;
            if (this.state.selectedStatus.value === TASKS_STATUS.OPEN && merchandiserID !== null) {
                status = TASKS_STATUS.BOOKED;
            } else if (status === TASKS_STATUS.BOOKED && merchandiserID === null) {
                status = TASKS_STATUS.OPEN;
            }
            //get his tasks to set the order
            let date = task.date_time_from.toDate();
            let merchandiserTasks = [];
            if (task.only_header) {
                merchandiserTasks = await getMerchandiserTaskHeadersByDate(merchandiserID, date);
            } else {
                merchandiserTasks = (await getMerchandiserTasksByDate(merchandiserID, date)).map((task) => task.data());
            }

            let index = merchandiserTasks.findIndex((t) => `${t.task_id}` === `${task.task_id}`);

            let dailyOrder = index === -1 ? merchandiserTasks.length : merchandiserTasks[index].dailyOrder;

            merchandiserTasks = merchandiserTasks.filter((t) => t.project_id === task.project_id);
            let order = index === -1 ? merchandiserTasks.length : merchandiserTasks[index].order;
            //change the current task uid and order
            await db
                .collection("Task")
                .doc(`${this.state.task.task_id}`)
                .update({
                    uid: merchandiserID,
                    order: order,
                    dailyOrder: dailyOrder,
                    remarks: this.state.remarks || "",
                    state: status || TASKS_STATUS.BOOKED,
                    modified_properties: ["uid", "order", "dailyOrder", "remarks", "state"],
                });

            task = {
                ...task,
                uid: merchandiserID,
                order: order,
                dailyOrder: dailyOrder,
                remarks: this.state.remarks || "",
                state: status || TASKS_STATUS.BOOKED,
            };

            this.setState({
                alertMsg: "Save Successful.",
                alertOpened: true,
                task: task,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    setAlertOpen = (bool) => {
        this.setState({ alertOpened: bool });
    };

    componentDidMount() {
        this.init();
    }
    render() {
        const classes = this.classes;
        const projectInfo = [
            { label: "Visit No.", value: this.props.taskID },
            { label: "Project No.", value: this.state.projectID },
            { label: "City", value: this.state.city },
            { label: "Supplier Branch", value: this.state.supplierBranch },
            { label: "Outlet Branch", value: this.state.outletBranch },
        ];
        const visitDetails = [
            { label: "Date", value: this.state.date },
            { label: "Duration", value: this.state.duration },
            { label: "Actual Time / Duration", value: this.state.actualWorkTime },
            { label: "Warehouse Work Time", value: this.state.warehouseWorkTime },
            { label: "Received At", value: this.state.receivedAt },
        ];

        if (this.state.disableStatus) {
            visitDetails.push({ label: "Status", value: this.state.selectedStatus.label });
        } else {
            visitDetails.push({
                type: "autoComplete",
                options: this.state.statusList,
                label: "Status",
                value: this.state.selectedStatus,
                onChange: this.statusChanged,
            });
        }

        // console.log(this.type);
        if (this.type === "insource") {
            if (this.state.disableMerchandiser) {
                visitDetails.push({ label: "Merchandiser", value: this.state.selectedMerchandiser.label });
            } else {
                visitDetails.push({
                    type: "autoComplete",
                    options: this.state.merchandisersList,
                    label: "Merchandiser",
                    value: this.state.selectedMerchandiser,
                    // value: this.state.selectedMerchandiser.label || "Not Assigned",
                    onChange: this.merchChanged,
                });
            }
        }

        return (
            <div className={classes.root}>
                <TransitionAlert
                    variant="filled"
                    open={this.state.alertOpened}
                    setOpen={this.setAlertOpen}
                    className={classes.stickyAlert}
                >
                    {this.state.alertMsg}
                </TransitionAlert>

                <FullPageLoader loading={this.state.loading} className={classes.loader} />
                <Card className={classes.contentCard}>
                    <ContentHeader title={`Visits Details (${this.type})`} />
                    <VisitInfoSection title="Project Info." fields={projectInfo} />
                    <VisitInfoSection title="Visit Details" fields={visitDetails} />
                    <Grid container item xs={12} sm={12} md={12} className={classes.remarksContainer}>
                        <TextField
                            className={classes.remarks}
                            id="task-remarks-input"
                            label="Remarks"
                            multiline
                            rows={4}
                            value={this.state.remarks}
                            onChange={this.remarksChanged}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container spacing={1} className={classes.buttons}>
                        <Grid container item xs={12} sm={12} md={2}>
                            <Grid item>
                                <Button
                                    className={classes.saveButton}
                                    classes={{ disabled: classes.disabledBtn }}
                                    onClick={this.save}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={10}
                            direction={"row-reverse"}
                            justifyContent={"flex-start"}
                        >
                            <Grid item>
                                <Button
                                    onClick={() =>
                                        this.props.gotoDailyTaskReport(
                                            this.state.task,
                                            this.state.city,
                                            this.state.outletBranch
                                        )
                                    }
                                    style={{}}
                                    className={classes.mdrButton}
                                >
                                    View Merchandiser Daily Report &#10095;
                                </Button>
                            </Grid>
                            <Grid item style={{ marginRight: 5 }}>
                                <PDFButton
                                    className={classes.pdfBtn}
                                    classes={{ disabled: classes.disabledBtn }}
                                    label={"Download Products Expiry & Damage Report"}
                                    disabled={!this.state.productRemovalExist}
                                    pdfComponent={
                                        this.state.productRemovalExist ? (
                                            <ProductExpiryAndDamagePdf
                                                info={{
                                                    companyData: this.context.companyData,
                                                    name: this.state.supplier.company_name,
                                                    phone: this.state.supplier.contact_information.company_phone,
                                                    // mpName: this.state.mp.company_name,
                                                    // mpPhone: this.state.mp.contact_information.company_phone,
                                                    exportedBy: this.state.username,
                                                    projectID: this.state.projectID,
                                                    taskID: this.props.taskID,
                                                    date: this.state.task.date_time_from.toDate(),
                                                    city: this.state.city,
                                                    outletBranch: this.state.outletBranch,
                                                    products: this.state.products,
                                                }}
                                            />
                                        ) : null
                                    }
                                    filename={`Products Expiry & Damage Report - Visit No ${this.props.taskID}`}
                                />
                                {/* <PDFViewer style={{ width: "100%", height: "70vh" }}>
                                                                <ProductExpiryAndDamagePdf
                                                                    info={{
                                                                        name: this.state.supplier_name,
                                                                        phone: this.state.supplier_phone,
                                                                        projectID: this.state.task.project_id,
                                                                        taskID: this.props.location.state.Tid,
                                                                        date: this.state.task.date_time_from.toDate(),
                                                                        city: this.state.city_name,
                                                                        outletBranch: this.state.outlet_branch_name,
                                                                        exportedBy: this.state.username,
                                                                        products: this.state.products,
                                                                    }}
                                                                />
                                                            </PDFViewer> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        );
    }
}

VisitDetailsContent.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(VisitDetailsContent);
