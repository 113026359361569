import { PLATFORM } from "../../constants/global";
import { firestore as db, firestoreWorker } from "../firebase";

const USER_COLLECTION = "User";
const ACCOUNT_STATUS = {
    INACTIVE: "0",
    ACTIVE: "1",
};

export const addUser = async (user) => {
    try {
        user.status = ACCOUNT_STATUS.ACTIVE;
        user.platform = PLATFORM;
        await firestoreWorker.collection(USER_COLLECTION).doc(user.uid).set(user);

        return user;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (id) => {
    try {
        return await db.collection(USER_COLLECTION).doc(id).get();
    } catch (error) {
        throw error;
    }
};

export const verifyUser = async (id) => {
    try {
        await db.collection(USER_COLLECTION).doc(id).update({ is_verified: true });
    } catch (error) {
        throw error;
    }
};

export const updateUser = async (id, data) => {
    try {
        return db.collection(USER_COLLECTION).doc(id).update(data);
    } catch (error) {
        throw error;
    }
};

export const queryUser = async (query) => {
    try {
        let ref = db.collection(USER_COLLECTION);
        for (const q of query) {
            ref = ref.where(q.field, q.operator, q.value);
        }

        return (await ref.get()).docs;
    } catch (error) {
        throw error;
    }
};
