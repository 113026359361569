import { Card, Grid } from "@material-ui/core";
import { flatten } from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { Filters, GalleryImageCard } from "../../components";
import { useAuth } from "../../contexts/auth-context";
import { CircularLoader, Header } from "../../core-ui/custom";
import { arrayChunks } from "../../helpers/array-helpers";
import { getToday } from "../../helpers/date-utils";
import { firestore } from "../../services/firebase";
import { getLinkedOutletBranchesBySupplierID } from "../../services/firestore/Branch_Outlet_Branch";
import { getAllCities } from "../../services/firestore/City";
import { getOutletBranches, getOutletBranchesByCompany } from "../../services/firestore/Outlet_Branch";
import { getProducts, getSupplierProducts } from "../../services/firestore/Product";
import { getSupplierProjects } from "../../services/firestore/Project";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { getLinkedOutlets } from "../../services/firestore/Supplier_Outlet_Product";
import { getTasksByID } from "../../services/firestore/Task";
import { queryTaskDetails } from "../../services/firestore/Task_Details";

//related to meterial ui package
import styles from "./styles";

const INITIAL_FILTERS = {
    startDate: {
        label: "Start Date",
        component: "date",
        initialValue: getToday(),
    },
    endDate: {
        label: "End Date",
        component: "date",
        initialValue: getToday(),
    },
    products: {
        label: "Product",
        options: [],
        initialValue: null,
    },
    cities: {
        label: "City",
        options: [],
        initialValue: null,
    },
    supplierBranches: {
        label: "Supplier Branch",
        options: [],
        initialValue: null,
    },
    outlets: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outletBranches: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },
    projects: {
        label: "Project",
        options: [],
        initialValue: null,
    },
    shelf_included: {
        label: "Shelf",
        component: "checkbox",
        initialValue: true,
        gridProps: { xs: 6, sm: 4, md: 3, lg: 1 },
    },
    extra_display_included: {
        label: "Extra Display",
        component: "checkbox",
        initialValue: true,
        gridProps: { xs: 6, sm: 4, md: 3, lg: 1 },
    },
};

const dataSourcesReducer = (state, { type, payload }) => {
    switch (type) {
        case "MULTI_SET":
            return {
                ...state,
                ...payload,
            };
        case "SET":
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case "RESET":
            return {
                ...state,
                [payload.key]: payload.initialValue,
            };
        default:
            return state;
    }
};

const initialState = {
    cities: [],
    products: [],
    projects: [],
    outlets: [],
    supplierBranches: [],
    outletBranches: [],
    linkedOutletBranches: [],
};

const Gallery = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    // states
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataSources, dispatchDataSources] = useReducer(dataSourcesReducer, initialState);
    const [taskDetails, setTaskDetails] = useState([]);

    const init = async () => {
        try {
            const { company_id } = companyData;
            // load filters options
            const [products, projects, cities, supplierBranches, outlets] = (
                await Promise.all([
                    getSupplierProducts(company_id),
                    getSupplierProjects(company_id),
                    getAllCities(),
                    getBranchesBySupplierID(company_id),
                    getLinkedOutlets(company_id),
                ])
            ).map((docs) => docs.map((doc) => doc.data()));

            const linkedOutletBranches = await getLinkedOutletBranchesBySupplierID(company_id);
            //flatten linked outlet branches
            const outletBranches = flatten(
                linkedOutletBranches.map((branch) => branch.outletBranches.map((docs) => docs.data()))
            );

            dispatchDataSources({
                type: "MULTI_SET",
                payload: {
                    products: products.map((p) => ({ value: p.product_id, label: p.En_name })),
                    projects: projects.map((p) => ({ value: p.project_id, label: p.projName })),
                    cities: cities.map((c) => ({ value: `${c.city_id}`, label: c.En_name })),
                    supplierBranches: supplierBranches.map((sb) => ({ value: sb.branch_id, label: sb.En_name })),
                    outlets: outlets.map((o) => ({ value: o.outlet_id, label: o.En_short_name })),
                    linkedOutletBranches: outletBranches,
                },
            });
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    };

    const search = async (values) => {
        try {
            setTaskDetails([]);
            setLoading(true);
            let {
                startDate,
                endDate,
                products,
                cities,
                supplierBranches,
                outlets,
                outletBranches,
                projects,
                shelf_included,
                extra_display_included,
            } = values;

            endDate.setHours(23, 59, 59, 59);
            const query = [
                { key: "picture_before_timestamp", operator: ">=", value: startDate },
                { key: "picture_before_timestamp", operator: "<=", value: endDate },
                { key: "supplier_id", operator: "==", value: companyData.company_id },
                // { key: "isEnded", operator: "==", value: true },
            ];
            if (shelf_included && !extra_display_included)
                query.push({ key: "extra_display", operator: "==", value: false });
            else if (!shelf_included && extra_display_included)
                query.push({ key: "extra_display", operator: "==", value: true });

            if (products?.value) query.push({ key: "product_id", operator: "==", value: products.value });
            // if(cities?.value) query.push({ key: "city_id", operator: "==", value: cities.value });
            if (supplierBranches?.value)
                query.push({ key: "supplier_branch_id", operator: "==", value: supplierBranches.value });
            if (outlets?.value) query.push({ key: "outlet_id", operator: "==", value: outlets.value });
            if (outletBranches?.value)
                query.push({ key: "outlet_branch_id", operator: "==", value: outletBranches.value });
            if (projects?.value) query.push({ key: "project_id", operator: "==", value: projects.value });

            //get task details
            let taskDetails = (await queryTaskDetails(query)).map((doc) => doc.data());

            //filter task details with no images
            taskDetails = taskDetails.filter((td) => td.picture_before || td.picture_after);
            // console.log(taskDetails[0].picture_before_timestamp.toDate());
            // get unique product ids
            let productIds = taskDetails.map((taskDetail) => taskDetail.product_id);
            productIds = [...new Set(productIds)];
            // get product info from Product collection
            const tdProducts = (await getProducts(productIds)).map((doc) => doc.data());
            // get unique outlet branch ids
            let outletBranchIds = taskDetails.map((taskDetail) => taskDetail.outlet_branch_id);
            outletBranchIds = [...new Set(outletBranchIds)];
            // get outlet branch info from Outlet_Branch
            const tdOutletBranches = (await getOutletBranches(outletBranchIds)).map((doc) => doc.data());
            // get unique task ids
            let taskIds = taskDetails.map((taskDetail) => `${taskDetail.task_id}`);
            taskIds = [...new Set(taskIds)];
            //get task info from Task collection
            const tasks = (await getTasksByID(taskIds)).map((doc) => doc.data());
            // set task, product, outlet branch info into task details
            for (const td of taskDetails) {
                const task = tasks.find((task) => task.task_id === td.task_id);
                const product = tdProducts.find((product) => product.product_id === td.product_id);
                const outletBranch = tdOutletBranches.find(
                    (outletBranch) => outletBranch.branch_id === td.outlet_branch_id
                );
                td.task = task;
                td.product = product;
                td.outlet_branch = outletBranch;
            }
            // set state of task details
            setTaskDetails(taskDetails);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const handleOutletsChange = async (option, context) => {
        try {
            if (!option) {
                dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: [] } });
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "outletBranches" },
                });
                return;
            }

            let outletBranches = (await getOutletBranchesByCompany(option.value)).map((doc) => doc.data());
            //filter out unlinked outlet branches
            outletBranches = outletBranches.filter((ob) =>
                dataSources.linkedOutletBranches.find((lob) => lob.branch_id === ob.branch_id)
            );

            outletBranches = outletBranches.map((ob) => ({ value: ob.branch_id, label: ob.En_short_name }));

            dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: outletBranches } });
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "outletBranches", options: outletBranches },
            });
        } catch (error) {
            throw error;
        }
    };

    const initFilters = (context) => {
        INITIAL_FILTERS.cities.options = dataSources.cities;
        INITIAL_FILTERS.supplierBranches.options = dataSources.supplierBranches;
        INITIAL_FILTERS.outlets.options = dataSources.outlets;
        INITIAL_FILTERS.outlets.onChange = handleOutletsChange;
        INITIAL_FILTERS.products.options = dataSources.products;
        INITIAL_FILTERS.projects.options = dataSources.projects;

        INITIAL_FILTERS.shelf_included.gridProps.className = classes.checkboxGrid;
        INITIAL_FILTERS.extra_display_included.gridProps.className = classes.checkboxGrid;

        context.setFilters(INITIAL_FILTERS);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <section className={classes.gallery}>
            <Header className={classes.galleryHeader}>Gallery</Header>
            {!initializing && <Filters init={initFilters} onSubmit={search} />}
            {/* <Card className={classes.contentCard}> */}
            {/* Gallery images */}
            <Grid container spacing={1} justifyContent="center">
                {/* loading */}
                {initializing || loading ? <CircularLoader /> : null}
                {taskDetails.map((taskDetail) => {
                    return (
                        <Grid className={classes.galleryContent} item xs={12} sm={6} md={3} lg={2} key={taskDetail.id}>
                            {!initializing && <GalleryImageCard info={taskDetail} />}
                        </Grid>
                    );
                })}
            </Grid>
            {/* </Card> */}
        </section>
    );
};

export default Gallery;
