import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    card: {
        position: "relative",
        height: "300px",
    },

    cardHeader: {
        position: "absolute",
        top: "16px",
        left: "16px",
        padding: "0",
    },
    cardContent: {
        width: "100%",
        height: "100%",
        padding: "0",
        paddingBottom: "0 !important",
    },

    centerText: {
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "calc(50% + 35px)",
        transform: "translate(-50%, -50%)",
        fontWeight: "bold",
    },
});

export default styles;
