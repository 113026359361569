import React, { useState, useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { CircularLoader, Header, TransitionAlert } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";
import { queryVideoTutorials } from "../../services/firestore/Video_Tutorials";
import { Button, Collapse, Grid } from "@material-ui/core";
import { LegalNoticeDialog, VideoCard } from "../../components";
import { firestore } from "../../services/firebase";
import { timestamp } from "../../helpers/date-utils";
import { PLATFORM } from "../../constants/global";
import VIDEOS_INFO from "../../constants/tutorials";
import { useGeolocation } from "../../contexts/geolocation-context";
import { setLog } from "../../services/firestore/Log";
import { useLocation } from "react-router-dom";
import { serverTimestamp } from "../../helpers/firebase-helpers";

const VideoTutorials = (props) => {
    const classes = styles();
    const { companyData, userData } = useAuth();
    const { isLoaded, position } = useGeolocation();
    const { pathname } = useLocation();

    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dialogOpened, setDialogOpened] = useState(true);

    const [dataEntryCollapsed, setDataEntryCollapsed] = useState(true);
    const [planningCollapsed, setPlanningCollapsed] = useState(true);
    const [operationsCollapsed, setOperationsCollapsed] = useState(true);
    const [mobileCollapsed, setMobileCollapsed] = useState(true);

    const [alert, setAlert] = useState({
        isOpen: false,
        message: "These Videos belong to Derwaza only. Please DO NOT SHARE OR COPY any of the videos.",
        variant: "filled",
        severity: "warning",
    });

    // const [videos, setVideos] = useState([]);

    const onAgreeToTerms = async () => {
        try {
            // const ref = firestore.collection("Terms").doc();
            // await ref.set({
            //     platfrom: PLATFORM,
            //     id: ref.id,
            //     uid: userData.uid,
            //     uid_type: "User",
            //     timestamp: timestamp(),
            //     version: "2022-07-21",
            // })
            setDialogOpened(false);
        } catch (error) {
            console.log(error);
        }
    };

    const setVideoLog = async (video) => {
        try {
            //if permission is denied or not loaded
            if (!position) return false;

            const name = `${userData.first_name} ${userData.surname}`;
            await setLog({
                account_type: `${userData.company_type} admin`,
                action: `opened tutorial video name: ${video.title} url: ${video.url}`,
                page: pathname,
                platform: PLATFORM,
                platform_type: "support",

                address: position.address,
                city: position.address_components[3]?.long_name,
                coordinates: {
                    lat: position.lat,
                    lng: position.lng,
                },
                timestamp: serverTimestamp(),

                uid: userData.uid,
                user_name: name,
                company_id: userData.company_id,
                company_type: userData.company_type,
            });
        } catch (error) {
            console.log(error);
        }
    };
    const init = useCallback(async () => {
        try {
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    return (
        <section className={classes.pageRoot}>
            <TransitionAlert
                variant={alert.variant}
                open={true}
                // setOpen={(bool) => setAlert((prev) => ({ ...prev, isOpen: bool }))}
                severity={alert.severity}
                className={classes.copyRightAlert}
                disableExit
                // timer={3000}
            >
                {alert.message}
            </TransitionAlert>
            <Header className={classes.pageHeader}>Tutorials</Header>
            {/* loading */}
            {initializing || loading ? <CircularLoader /> : null}

            <Button className={classes.collapseButton} onClick={() => setDataEntryCollapsed((prev) => !prev)}>
                Data Entry
            </Button>
            <Collapse in={dataEntryCollapsed}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.videosContainer}
                >
                    {!initializing &&
                        VIDEOS_INFO.filter((v) => v.section === "data entry").map((video) => {
                            return (
                                <Grid
                                    className={classes.galleryContent}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={2}
                                    key={video.id}
                                >
                                    <VideoCard video={video} onClick={setVideoLog} />
                                </Grid>
                            );
                        })}
                </Grid>
            </Collapse>
            <Button className={classes.collapseButton} onClick={() => setPlanningCollapsed((prev) => !prev)}>
                Planning
            </Button>
            <Collapse in={planningCollapsed}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.videosContainer}
                >
                    {!initializing &&
                        VIDEOS_INFO.filter((v) => v.section === "planning").map((video) => {
                            return (
                                <Grid
                                    className={classes.galleryContent}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={2}
                                    key={video.id}
                                >
                                    <VideoCard video={video} onClick={setVideoLog} />
                                </Grid>
                            );
                        })}
                </Grid>
            </Collapse>

            <Button className={classes.collapseButton} onClick={() => setOperationsCollapsed((prev) => !prev)}>
                Operations
            </Button>
            <Collapse in={operationsCollapsed}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.videosContainer}
                >
                    {!initializing &&
                        VIDEOS_INFO.filter((v) => v.section === "operations").map((video) => {
                            return (
                                <Grid
                                    className={classes.galleryContent}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={2}
                                    key={video.id}
                                >
                                    <VideoCard video={video} onClick={setVideoLog} />
                                </Grid>
                            );
                        })}
                </Grid>
            </Collapse>
            <Button className={classes.collapseButton} onClick={() => setMobileCollapsed((prev) => !prev)}>
                Merchandiser Mobile App
            </Button>
            <Collapse in={mobileCollapsed}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.videosContainer}
                >
                    {!initializing &&
                        VIDEOS_INFO.filter((v) => v.section === "mobile").map((video) => {
                            return (
                                <Grid
                                    className={classes.galleryContent}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={2}
                                    key={video.id}
                                >
                                    <VideoCard video={video} onClick={setVideoLog} />
                                </Grid>
                            );
                        })}
                </Grid>
            </Collapse>
            {/* <LegalNoticeDialog open={dialogOpened} onClose={onAgreeToTerms} /> */}
        </section>
    );
};

VideoTutorials.propTypes = {
    classes: PropTypes.object,
};

export default VideoTutorials;
