import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import ReactPlayer from "react-player/vimeo";
import { useState } from "react";

const VideoDetailsDialog = ({ open, onClose, video }) => {
    const classes = styles();
    const [showPlayer, setShowPlayer] = useState(false);

    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose} className={classes.galleryItemDialog}>
            <DialogTitle onClose={onClose}>
                <Typography className={classes.productName} variant="h5">
                    {video.title}
                </Typography>
                <Typography className={classes.displayTypeAndLocation} variant="h5">
                    {video.description}
                </Typography>

                <div className={classes.closeButton} onClick={onClose}>
                    x
                </div>
            </DialogTitle>

            <DialogContent dividers className={classes.cardContent}>
                {/* <iframe
                    src="https://player.vimeo.com/video/732009475?h=c9369bb379"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                ></iframe> */}
                <ReactPlayer
                    url={video.url}
                    controls
                    width={"100%"}
                    height="100%"
                    onReady={() => setShowPlayer(true)}
                    style={{ opacity: showPlayer ? "1" : "0", transition: "opacity .75s ease-in-out" }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default VideoDetailsDialog;
