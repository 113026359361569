import { flatten } from "lodash/fp";
import { CONTRACT_MERCH_STATUS, MERCH_CONTRACT_STATUS } from "../../constants/constants-lagacy";
import { EMPLOYEE_TYPES } from "../../constants/global";
import { get } from "../../helpers/object-utils";
import { firestore as db } from "../firebase";
import { queryMerchandiserExclusivityContracts } from "./Merchandiser_Exclusivity_Contract";

export const getMerchandiser = async (merchandiserId) => {
    try {
        return await db.collection("Merchandiser").doc(merchandiserId).get();
    } catch (error) {
        throw error;
    }
};

export let getMPMerchandisers = async (mpID) => {
    let merchandisers = [];

    try {
        //* get merchandisers
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.owner_id", "==", mpID)
                .where("account_information.type", "==", "mp_employee")
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return merchandisers;
};

export let getSupplierEmployees = async (supplierID) => {
    let merchandisers = [];

    try {
        //* get employees
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.owner_id", "==", supplierID)
                .where("account_information.type", "in", [
                    EMPLOYEE_TYPES.SUPPLIER_MERCHANDISER,
                    EMPLOYEE_TYPES.SUPPLIER_SUPERVISOR,
                ])
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return merchandisers;
};

export let getSupplierMerchandisers = async (supplierID) => {
    let merchandisers = [];

    try {
        //* get merchandisers
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.owner_id", "==", supplierID)
                .where("account_information.type", "==", "supplier_employee")
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return merchandisers;
};

export let getMerchandisersBySupplierBranch = async (supplierBranchID) => {
    let merchandisers = [];
    try {
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.supplier_branch_id", "==", supplierBranchID)
                .where("account_information.type", "==", "supplier_employee")
                .get()
        ).docs;

        return merchandisers;
    } catch (error) {
        throw error;
    }
};

export let getSupervisorsBySupplierBranch = async (supplierBranchID) => {
    let supervisors = [];
    try {
        supervisors = (
            await db
                .collection("Merchandiser")
                .where("account_information.supplier_branch_id", "==", supplierBranchID)
                .where("account_information.type", "==", EMPLOYEE_TYPES.SUPPLIER_SUPERVISOR)
                .get()
        ).docs;

        return supervisors;
    } catch (error) {
        throw error;
    }
};

export let getMerchandisersByMPBranch = async (MPBranchID) => {
    let merchandisers = [];
    try {
        merchandisers = (
            await db.collection("Merchandiser").where("account_information.mp_branch_id", "==", MPBranchID).get()
        ).docs;

        return merchandisers;
    } catch (error) {
        throw error;
    }
};

export const getMerchandisersListByTask = async (task) => {
    try {
        const supplierBranchID = `${task.supplier_branch_id}`;
        let result = await getMerchandisersBySupplierBranch(supplierBranchID);

        return flatten(result);
    } catch (error) {
        throw error;
    }
};

export const getMerchandiserFromExclContract = async (exclusivityContract, branchID = null, onlyActive = true) => {
    try {
        let contractMerch = exclusivityContract.merchandisers.filter((merch) => merch.supplier_branch_id !== null);

        if (branchID) {
            contractMerch = contractMerch.filter((merch) => merch.supplier_branch_id === branchID);
        }
        if (onlyActive) {
            contractMerch = contractMerch.filter((merch) => merch.status === CONTRACT_MERCH_STATUS.ACTIVE);
        }
        const merchandisers = await Promise.all(
            contractMerch.map(async (merch) => {
                const merchandiser = await getMerchandiser(merch.id);
                return merchandiser;
            })
        );

        return merchandisers;
    } catch (error) {
        throw error;
    }
};

export const getOutsourcedMerchandisers = async (supplierID, date = null, branchID = null) => {
    try {
        //get contracts
        let contracts = (await queryMerchandiserExclusivityContracts([{ key: "supplier_id", value: supplierID }])).map(
            (doc) => doc.data()
        );

        // contracts = contracts.filter((contract) => {
        //     const { start_date, end_date, status } = contract;
        //     return start_date.toDate() <= date && end_date.toDate() >= date && status === MERCH_CONTRACT_STATUS.ACTIVE;
        // });

        //get merchandisers
        let result = await Promise.all(
            contracts.map(async (contract) => {
                const merchandisers = await getMerchandiserFromExclContract(contract, branchID, true);
                return { merchandisers, contract };
            })
        );

        let allMerch = [];
        for (const contractMerchandisers of result) {
            const { contract, merchandisers } = contractMerchandisers;
            allMerch = [
                ...allMerch,
                ...merchandisers.map((merch) => ({
                    info: merch.data(),
                    contract: contract,
                })),
            ];
        }

        return allMerch;
    } catch (error) {
        throw error;
    }
};

export const updateMerchandiser = async (merchandiserID, data) => {
    try {
        return await db.collection("Merchandiser").doc(merchandiserID).update(data);
    } catch (error) {
        throw error;
    }
};

export const getMerchandisersFromTasks = async (tasks, savedMerchandisers = []) => {
    try {
        // get unique uid from tasks
        let uids = [...new Set(tasks.map((task) => task.uid))];
        // filter uids which have new merchandisers
        uids = uids.filter((uid) => {
            if (!uid) return false;
            const isSavedMerchandiser = savedMerchandisers.find((merchandiser) => {
                return uid === merchandiser.uid;
            });
            return !isSavedMerchandiser;
        });

        // get merchandiser info from uids
        let newMerchandisers = await Promise.all(uids.map(async (uid) => getMerchandiser(uid)));
        //doc to data
        newMerchandisers = newMerchandisers.map((merchandiser) => merchandiser.data());
        // merge saved merchandisers with new merchandisers
        return [...savedMerchandisers, ...newMerchandisers];
    } catch (error) {
        throw error;
    }
};
//===============================================================

//########################## Utilities ##########################

//===============================================================

export let getMerchandiserName = (merchandiser) => {
    let merchName = get(merchandiser, "personal_information.name.english");
    merchName = `${merchName.first}${merchName.middle ? ` ${merchName.middle}` : ""}${
        merchName.last ? ` ${merchName.last}` : ""
    }`;
    return merchName;
};
