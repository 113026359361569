import React, { useMemo } from "react";
import { FormControl, IconButton, makeStyles } from "@material-ui/core";
import { Button } from "../../custom";
import isArray from "lodash/fp/isArray";
import isString from "lodash/fp/isString";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    formControl: {
        // margin: theme.spacing(1),
    },
    input: {
        display: "none",
    },
    label: {
        height: "100%",
    },
}));

const acceptVariants = {
    word: ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    images: ".jpg, .png, .gif",
    excel: ".xlsx,.xls,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const makeAcceptString = (accept) => {
    if (!accept || !accept.length) {
        return "";
    }

    if (isString(accept)) {
        return acceptVariants[accept] ? acceptVariants[accept] : accept;
    }

    if (isArray(accept)) {
        const len = accept.length;
        return accept.reduce((acc, curr, currIndex) => {
            if (acceptVariants[curr]) {
                acc += acceptVariants[curr];
            } else if (curr && curr.length && curr.trim().length) {
                acc += curr.trim();
            }

            if (len > 1 && currIndex < len - 1) {
                acc += ",";
            }

            return acc;
        }, "");
    }

    return "";
};

const FileButton = ({
    accept,
    multiple = false,
    id = `file-input-${Math.random()}`,
    onChange,
    children,
    iconButton = false,
    ...otherProps
}) => {
    const classes = useStyles();

    const acceptString = useMemo(() => makeAcceptString(accept), [accept]);

    let button = (
        <Button variant="contained" component="span" {...otherProps}>
            {children || "Upload"}
        </Button>
    );

    if (iconButton) {
        button = (
            <IconButton variant="contained" component="span" {...otherProps}>
                {children || "Upload"}
            </IconButton>
        );
    }
    return (
        <div className={classes.container}>
            <FormControl className={classes.formControl}>
                <input
                    id={id}
                    accept={acceptString}
                    className={classes.input}
                    multiple={multiple}
                    type="file"
                    onChange={onChange}
                />
                <label htmlFor={id}>{button}</label>
            </FormControl>
        </div>
    );
};

export default FileButton;
