import { makeStyles } from "@material-ui/core";

export default makeStyles({
    imageFileButtonWrap: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    imageFileButton: {
        padding: 0,
        minWidth: "auto",
        width: "52px",
        height: "52px",
        overflow: "hidden",

        "& span": {
            height: "100%",
        },
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
    },
});
