import React, { useState, useEffect } from "react";
import styles from "./styles";
import { FileButton } from "..";
import imagePlaceholder from "../../../assets/images/placeholder.jpg";
import { useField } from "formik";

const ImageFileButton = ({ name, ...otherProps }) => {
    const [field, meta, helpers] = useField(name);
    const [imgURL, setImgURL] = useState(imagePlaceholder);
    const classes = styles();

    const handleFileSelection = (e) => {
        if (e.target.files.length <= 0) {
            helpers.setValue(null);
            return;
        }

        const file = e.target.files[0];
        helpers.setTouched(true);
        helpers.setValue(file);
    };

    const config = {
        ...otherProps,
        iconButton: true,
        className: classes.imageFileButton,
    };

    useEffect(() => {
        if (field.value) {
            const fr = new FileReader();
            fr.onload = (ev) => {
                setImgURL(ev.target.result);
            };
            fr.readAsDataURL(field.value);
        }
    }, [field.value]);

    if (meta && meta.touched && meta.error) {
    }

    return (
        <div className={classes.imageFileButtonWrap}>
            <FileButton className={classes.uploadButton} onChange={handleFileSelection} accept="images" {...config}>
                <img src={imgURL} alt={name} />
            </FileButton>
            <span>{meta.error}</span>
        </div>
    );
};

export default ImageFileButton;
