export const PLATFORM = "emaham";
export const PLATFORM_FULL = "emaham-supplier";
export const TASK_TYPES = {
    REGULAR: "regular",
    HANDOVER: "handover",
    MP_INSOURCE: "mp-insource",
    MP_OUTSOURCE: "mp-outsource",
    SUPPLIER_INSOURCE: "self-service-simple",
};

export const EMPLOYEE_TYPES = {
    SUPPLIER_MERCHANDISER: "supplier_employee",
    SUPPLIER_SUPERVISOR: "supplier_supervisor",
    MP_MERCHANDISER: "mp_employee",
    MP_SUPERVISOR: "mp_supervisor",
    STORE_MERCHANDISER: "store_merchandiser",
    STORE_WAREHOUSE_KEEPER: "warehouse_keeper",
    FREELANCE_MERCHANDISER: "regular",
};
